import { Component, OnInit } from '@angular/core';
import {PrimeNGConfig, TreeNode} from "primeng/api";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {NodeProductionService} from "../services/node-production.service";
import {CouchdbService} from "../services/couchdb.service";
import {NotificationsService} from "../services/notifications.service";
import {HttpHeaders} from "@angular/common/http";
import {AddToTreeComponent} from "../add-to-tree/add-to-tree.component";

@Component({
  selector: 'app-tree-pre-production',
  templateUrl: './tree-pre-production.component.html',
  styleUrls: ['./tree-pre-production.component.css']
})
export class TreePreProductionComponent implements OnInit {

  treeData: TreeNode[] = [];
  cols: any[] = [];
  jsonObject: { [key: string]: any } = {};
  searchQuery: string = '';
  filteredTreeData: TreeNode[] = [];
  companiesList: any[] = [];
  selectedCompany: any;
  addNode_Label: string = '';
  addNode_French: string = '';
  addNode_English: string = '';
  addNode_Description: string = '';
  addNode_Visibility: boolean = true;
  treeDataCompany: TreeNode[] = [];
  ref: DynamicDialogRef | undefined;
  existingClientID: any = null;
  newClientID: string = '';
  revision:string='';
  loading: boolean = false;
  revisionMap= new Map<string, string>();
  isDescriptionVisible: boolean = false;
  constructor(
    private nodeProductionService: NodeProductionService,
    private primengConfig: PrimeNGConfig,
    private couchdbService: CouchdbService,
    public dialogService: DialogService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    this.loadCompanies();


    this.primengConfig.ripple = true;
    this.cols = [
      { field: 'name', header: 'Name', width: '300px', sortable: true },
      {
        field: 'Wording.french',
        header: 'French',
        width: '300px',
        sortable: true,
      },
      {
        field: 'Wording.english',
        header: 'English',
        width: '300px',
        sortable: true,
      },
      {
        field: 'Visibility',
        header: 'Visibility',
        width: '100px',
        sortable: true,
      },
      {
        field: 'required',
        header: 'Required',
        width: '100px',
        sortable: true,
      },
      {
        field: 'Code',
        header: 'Code',
        width: '800px',
        sortable: true,
      },
    ];
  }
  loadCompanies() {
    this.couchdbService.getAllPreProductionComapnies().subscribe((data: any) => {
      if (data && data.length > 0) {
        this.companiesList = data.map((row: any) => {
          this.revisionMap.set(row.id,row.value.rev);
          return {
            label: row.id,
            data: row.id,
          };
        });
      } else {
        console.error(
          'Invalid data structure: Missing or empty data array.',
          data
        );
      }
    });
  }

  onNodeSelectCompany(event: any):void {
    const company = event.node.label;
    this.loadTreeData(company);
    this.searchQuery = '';
  }



  save(
    company: string = this.selectedCompany.label,
    data: TreeNode<any>[] = this.treeData
  ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })

    var index = 0;
    data.forEach((item) => {
      const { label, parent, children, data } = item;
      const { Wording, Visibility, name ,description} = data;
      var child = this.nodeProductionService.processChildren2(children);
      this.jsonObject[index] = {
        Wording, Visibility, name ,description,
        children: child,
      };
      index++;
    });
    this.jsonObject['_id'] = company;
    this.jsonObject['_rev'] = this.revisionMap.get(company);

    this.nodeProductionService
      .updateDocument(company, this.jsonObject, headers)
      .subscribe(
        (response:any) => {
          this.notificationsService.sucessMessage(
            'Success',
            'Item been modified for ' + company
          );
          console.log("the updated document is",this.jsonObject)
          //const res=response
          this.revisionMap.set(response._id,response._rev)
          console.log("after update",this.revisionMap);
        },
        (errorResponse) => {
          console.error('Request failed:', errorResponse);
          if (errorResponse.status === 404) {
            // If conflict error (status code 409) received from the server
            const errorMessage = errorResponse.error.message; // Assuming the error message is in the "message" field of the error response
            this.notificationsService.errorMessage('Error', errorMessage);
          } else {
            // For other errors, display a generic error message
            this.notificationsService.errorMessage(
              'Error',
              'Request failed. Please try again later.'
            );
          }
        }
      );
  }

  loadTreeData(client: string) {
    this.loading = true;
    this.nodeProductionService.getPreProductionFileSystem(client).subscribe(
      (data: any) => {
        this.treeData = this.nodeProductionService.processTreeData(data);
        console.log("test", this.treeData)
        this.loading = false;
      },
      (error: any) => {
        console.error('error fetching tree data', error);
      }
    );
  }



  logger(data: any) {
    console.log(data);
  }


  formatName(name: string): string {
    if (name != null) {
      return name
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/(?:^|\s)\S/g, function (a) {
          return a.toUpperCase();
        });
    } else {
      return '';
    }
  }

  show(
    parent: string,
    label: string,
    english: string,
    french: string,
    description:string,
    visibility: boolean
  ) {
    this.ref = this.dialogService.open(AddToTreeComponent, {
      header: 'Add node',
      showHeader: false,
      width: '70%',
      contentStyle: {
        overflow: 'auto',
        background: '#e6e8ea',
        color: '#6c757d',
        border: 'none',
      },
      baseZIndex: 10000,
      data: {
        parent: parent,
        label: label,
        english: english,
        french: french,
        description: description,
        visibility: visibility,
      },
    });
    this.ref.onClose.subscribe(() => {
      console.log('Dialog closed');
    });
  }

  isAnyFieldEmpty(): boolean {
    return (
      this.addNode_Label.trim() === '' ||
      this.addNode_English.trim() === '' ||
      this.addNode_French.trim() === ''  ||
      this.addNode_Description.trim() === ''

    );
  }

  isNewClientFieldEmpty(): boolean {
    return this.existingClientID === null || this.newClientID.trim() === '';
  }


  CloneCompanyInProduction() {
    this.couchdbService
      .CloneCompanyInProduction(this.existingClientID.label, this.newClientID)
      .subscribe((elemnt) => {});
    window.location.reload();
  }

  switch() {
    this.isDescriptionVisible = !this.isDescriptionVisible
    if(this.isDescriptionVisible) {
      this.cols = [
        { field: 'name', header: 'Name', width: '300px', sortable: true },
        {
          field: 'Wording.french',
          header: 'French',
          width: '300px',
          sortable: true,
        },
        {
          field: 'Wording.english',
          header: 'English',
          width: '300px',
          sortable: true,
        },
        {
          field: 'Visibility',
          header: 'Visibility',
          width: '100px',
          sortable: true,
        },
        {
          field: 'required',
          header: 'Required',
          width: '100px',
          sortable: true,
        },
        {
          field: 'Code',
          header: 'Code',
          width: '800px',
          sortable: true,
        },
        {
          field: 'description',
          header: 'description',
          width: '800px',
          sortable: true,
        }
      ];
    }
    else{
      this.cols = [
        { field: 'name', header: 'Name', width: '300px', sortable: true },
        {
          field: 'Wording.french',
          header: 'French',
          width: '300px',
          sortable: true,
        },
        {
          field: 'Wording.english',
          header: 'English',
          width: '300px',
          sortable: true,
        },
        {
          field: 'Visibility',
          header: 'Visibility',
          width: '100px',
          sortable: true,
        },
        {
          field: 'required',
          header: 'Required',
          width: '100px',
          sortable: true,
        },
        {
          field: 'Code',
          header: 'Code',
          width: '800px',
          sortable: true,
        }
      ];
    }
  }

}
