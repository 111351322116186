import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {
  private url = environment.API_URL + '/user';
  private isAdminSubject = new BehaviorSubject<boolean>(this.getAdminFromStorage());
  isAdmin$ = this.isAdminSubject.asObservable();

  constructor(private http: HttpClient,private router: Router) {
  }

  getUserByUsername(username: string) {
    const url = `${this.url}/username/${username}`;
    return this.http.get(url);
  }
  getAllUsers(): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/all`);
  }
  setIsAdmin(value: boolean): void {
    this.isAdminSubject.next(value);
    localStorage.setItem('isAdmin', JSON.stringify(value)); // Persist isAdmin in localStorage
  }
  private getAdminFromStorage(): boolean {
    const storedIsAdmin = localStorage.getItem('isAdmin');
    return storedIsAdmin ? JSON.parse(storedIsAdmin) : false;
  }
}
