import { Component, OnInit } from '@angular/core';
import { TreeNode, MessageService } from 'primeng/api';
import { NodeService } from '../services/node.service';
import { HttpHeaders } from '@angular/common/http';
import { CouchdbService } from '../services/couchdb.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotificationsService } from '../services/notifications.service';

@Component({
  selector: 'app-add-to-tree',
  templateUrl: './add-to-tree.component.html',
  styleUrls: ['./add-to-tree.component.css'],
})
export class AddToTreeComponent implements OnInit {
  treeData: TreeNode[] = [];
  cols: any[] = [];
  jsonObject: { [key: string]: any } = {};
  searchQuery: string = '';
  filteredTreeData: TreeNode[] = [];
  companiesList: any[] = [];
  selectedCompany: any;
  addNode_Label: string = '';
  addNode_French: string = '';
  addNode_English: string = '';
  addNode_Description: string = '';
  addNode_Visibility: boolean = true;
  addNode_Required: boolean = false;
  treeDataCompany: TreeNode[] = [];
  parent_label: string = '';
  successMessage: string;
  errorMessage: string;

  constructor(
    private nodeService: NodeService,
    private couchdbService: CouchdbService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private notificationsService: NotificationsService
  ) {
  }

  ngOnInit(): void {
    this.loadCompanies();
    this.cols = [
      {field: 'company', header: 'Name', width: '300px', sortable: true},
      {
        field: 'Wording.french',
        header: 'French',
        width: '300px',
        sortable: true,
      },
      {
        field: 'Wording.english',
        header: 'English',
        width: '300px',
        sortable: true,
      },
      {
        field: 'Visibility',
        header: 'Visibility',
        width: '100px',
        sortable: true,
      },
      {
        field: 'required',
        header: 'Required',
        width: '100px',
        sortable: true,
      },
      {
        field: 'description',
        header: 'description',
        width: '100px',
        sortable: true,
      }
    ];
    this.parent_label = this.config.data.parent;
    this.addNode_Label = this.config.data.label;
    this.addNode_French = this.config.data.french;
    this.addNode_English = this.config.data.english;
    this.addNode_Description = this.config.data.description;
    this.addNode_Visibility = this.config.data.visibility;
    this.addNode_Required=this.config.data.required;
  }

  onValueChange(newValue: any, rowData: any, fieldPath: string[]): void {
    if (fieldPath.length === 1) {
      rowData[fieldPath[0]] = newValue;
    } else {
      const firstField = fieldPath[0];
      const secondField = fieldPath[1];
      rowData[firstField] = rowData[firstField] || {};
      rowData[firstField][secondField] = newValue;
    }
  }

  formatName(name: string): string {
    if (name != null) {
      return name
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/(?:^|\s)\S/g, function (a) {
          return a.toUpperCase();
        });

    } else {
      return '';
    }
  }

  loadCompanies() {
    this.couchdbService.getAllComapnies().subscribe((data: any) => {
      if (Array.isArray(data)) {
        if (data && data.length > 0) {
          this.companiesList = data.map((row: any) => ({
            label: row.id,
            data: row.id,
          }));
        }

        this.companiesList.forEach((element: any) => {
          let node: TreeNode = {
            label: element.label,
            children: [],
            data: {
              name: this.unformatName(element.label),
              company: element.label,
              Visibility: this.addNode_Visibility,
              description: this.addNode_Description,
              Wording: {
                english: this.addNode_English,
                french: this.addNode_French,
              },
            },
          };
          this.treeData.push(node);
          this.treeData = [...this.treeData];
        });
      }
    });
  }


  logger(data: any) {
    console.log(data);
  }

  save(
    company: string = this.selectedCompany.label,
    data: TreeNode<any>[] = this.treeData
  ) {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      var index = 0;
      data.forEach((item) => {
        const {label, parent, children, data} = item;
        const {Wording, Visibility, name, description} = data;
        var child = this.nodeService.processChildren2(children);
        this.jsonObject[index] = {
          Wording,
          name,
          Visibility,
          children: child,
          description
        };
        index++;
      });
      this.jsonObject['_id'] = company;
      this.nodeService
        .updateDocument(company, this.jsonObject, headers)
        .subscribe(() => {
          this.notificationsService.sucessMessage(
            'Success',
            'Item been added successfully to ' + company
          );
        });
    } catch (error) {
      console.error(error);
    }
  }


  unformatName(name: string) {
    return name.trim().replace(/ /g, '_').toUpperCase();
  }

  createNode(
    label: string,
    english: string,
    french: string,
    description: string,
    visibility: boolean,
    required:boolean
  ): TreeNode {
    return {
      label: this.unformatName(label),
      children: [],
      data: {
        name: this.unformatName(label),
        Visibility: visibility,
        description: description,
        required:required,
        Wording: {
          english: english.trim(),
          french: french.trim(),
        },
      },
    };
  }

  private findNodeByLabel(
    nodes: TreeNode<any>[],
    label: string
  ): TreeNode<any> | undefined {
    return nodes.find((node) => node.label === label);
  }

  createAndAddNodeToTree(
    tree: TreeNode<any>[],
    label: string,
    english: string,
    french: string,
    description: string,
    visibility: boolean,
    required:boolean
  ): TreeNode {
    const node = this.createNode(label, english, french, description, visibility,required);
    console.log("treeeeeeeeeeenode", tree);
    return node;
  }

  isKeyRedundant(key: string, treeNode: any): boolean {
    const parts = key.split('.');
    let parent;
    let remainingKey;
    if (parts[0] !== '') {
      parent = this.unformatName(parts[0]);
      remainingKey = parts.slice(1).join('.');
    } else {
      parent = this.unformatName(parts[1]);
      remainingKey = '';
    }
    // Iterate through the tree nodes
    for (const element of treeNode) {
      if (element.label === parent) {
        if (remainingKey === '') {
          // If there are no remaining parts, it's redundant
          return true;
        } else {
          // Recursively check the remaining key within the children of this node
          return this.isKeyRedundant(remainingKey, element.children);
        }
      }
    }

    // If no matching node is found, the key is not redundant
    return false;
  }

  /*  saveAllNodes(map: Map<string, TreeNode<any>[]>): void {
      const jsonObject = JSON.stringify(Array.from(map.entries()).map(([key, value]) => ({ key, value })));
      this.nodeService.saveAllNodes(jsonObject)
        .subscribe(response => {
          console.log('Response:', response);
        }, error => {
          console.error('Error:', error);
        });
    }*/

  /*addNode() {
    const keys = this.parent_label.split('.');
    const final_key = this.parent_label + '.' + this.addNode_Label;
    this.companiesList.forEach((company) => {
      // browses all companies
      try {
        this.nodeService.getFileSystem(company.label).subscribe(
          (data: any) => {
            this.treeDataCompany = this.nodeService.processTreeData(data); // changes the format of the data
            if (this.isKeyRedundant(final_key, this.treeDataCompany)) {
              this.notificationsService.errorMessage(
                'Error',
                'The key is redundant!'
              );
            } else {
              let currentNode = this.treeDataCompany; // the data of the every company
              if (this.parent_label == '') {
                this.treeData.forEach((element) => {
                    if (element.data.company == company.label) {
                      // looks for the position to add the node
                      this.treeDataCompany.push(
                        // adds the node at the suitable place
                        this.createNode(
                          this.addNode_Label,
                          element.data.Wording.english,
                          element.data.Wording.french,
                          element.data.description,
                          element.data.Visibility
                        )
                      )
                      this.save(company.label, this.treeDataCompany) // saves the node

                    }
                  },

                );
              } else {
                for (let i = 0; i < keys.length; i++) {
                  const currentKey = keys[i];
                  const foundNode = this.findNodeByLabel(
                    currentNode,
                    currentKey
                  );

                  if (foundNode) {
                    currentNode = foundNode.children!;
                    if (i === keys.length - 1) {
                      this.treeData.forEach((element) => {
                          if (element.data.company == company.label) {
                            this.createAndAddNodeToTree(
                              foundNode.children!,
                              this.addNode_Label,
                              element.data.Wording.english,
                              element.data.Wording.french,
                              element.data.description,
                              element.data.Visibility
                            );
                            this.save(company.label, this.treeDataCompany)
                          }

                        },
                      );

                    }
                  } else {
                    break;
                  }
                }
              }
            }
          },
          (error: any) => {
            console.error('error fetching tree data', error);
            this.notificationsService.errorMessage(
              'Error',
              'Item was not added to the tree'
            );
          }
        );
        //this.ref.close();
      } catch (msg) {
        this.notificationsService.errorMessage(
          'Error',
          'Item was not added to the tree'
        );
        console.error(msg);
      }
    });
    this.ref.close();
  }*/

  addNode() {
    const keys = this.parent_label.split('.');
    console.log("keysListttttt", keys);

    // Construct the final key
    const final_key = this.unformatName(this.parent_label) + '.' + this.unformatName(this.addNode_Label);

    // Create the new node object
    const newNode = this.createNode(
      this.addNode_Label,
      this.addNode_English,
      this.addNode_French,
      this.addNode_Description,
      this.addNode_Visibility,
      this.addNode_Required
    );

    // Call the service to save the node
    this.nodeService.saveAllNodes(final_key, newNode).subscribe(
      (response: any) => {
        // Check if response contains the success and failed documents
        if (response.successfulDocuments || response.failedDocuments) {
          const successfulDocs = response.successfulDocuments || [];
          const failedDocs = response.failedDocuments || [];

          // Display success messages
          if (successfulDocs.length > 0) {
            this.notificationsService.sucessMessage('Success', 'Documents updated successfully');
            successfulDocs.forEach((doc: any) => {
              this.notificationsService.sucessMessage(` ${doc.documentId},  ${doc.message}`);
            });
          }

          // Display failure messages
          if (failedDocs.length > 0) {
            this.notificationsService.errorMessage('Error', 'Some documents failed to update');
            failedDocs.forEach((doc: any) => {
              this.notificationsService.errorMessage('Document Update Failure', ` ${doc.documentId}, Reason: ${doc.message}`);
            });
          }

          console.log("The updated document details are:", response);
        } else {
          // In case no documents were updated
          const message = response.message || 'No documents were updated.';
          this.notificationsService.errorMessage('Error', message);
        }
      },
      (errorResponse) => {
        console.error('Request failed:', errorResponse);
        const errorMessage = errorResponse.error?.message || 'Request failed. Please try again later.';
        this.notificationsService.errorMessage('Error', errorMessage);
      }
    );

    // Close the modal or window after the operation
    this.closeWindow();
  }


  closeWindow() {
    // If you are using a dynamic dialog, close it like this
    if (this.ref) {
      this.ref.close();
    }
  }
}
