import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthenticationGuard } from './guards/authentication.guard';
import { TreeComponent } from './tree/tree.component';
import { CssComponent } from './css/css.component';
import { AddToCssComponent } from './add-to-css/add-to-css.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { UpdateFileComponent } from './update-file/update-file.component';
import { AuditComponent } from './audit/audit.component';
import { SpringCloudConfigComponent } from './spring-cloud-config/spring-cloud-config.component';
import {WhiteListComponent} from "./white-list/white-list.component";
import {AdminGuard} from "./guards/admin.guard";
import {TreePreProductionComponent} from "./tree-pre-production/tree-pre-production.component";
import {DatabaseChangeValidatorComponent} from "./database-change-validator/database-change-validator.component";
import {CssPreProductionComponent} from "./css-pre-production/css-pre-production.component";
import {AddedUsersInterfaceComponent} from "./added-users-interface/added-users-interface.component";
import {StrategyComponent} from "./components/strategy/strategy.component";



const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },


  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'addedUser',
    component: AddedUsersInterfaceComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [AuthenticationGuard,AdminGuard],

  },
  {
    path: 'tree',
    component: TreeComponent,
    canActivate: [AuthenticationGuard]

  },
  {
    path: 'treePreProduction',
    component: TreePreProductionComponent,
    canActivate: [AuthenticationGuard,AdminGuard]
  },
  {
    path: 'add',
    component: AddToCssComponent,
    canActivate: [AuthenticationGuard]

  },
  {
    path: 'css',
    component: CssComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'cssPreProduction',
    component: CssPreProductionComponent,
    canActivate: [AuthenticationGuard,AdminGuard],
  },
  {
    path: 'validate-changes',
    component: DatabaseChangeValidatorComponent,
    canActivate: [AuthenticationGuard,AdminGuard],
  },
  {
    path: 'strategy',
    component: StrategyComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'upadate-file',
    component: UpdateFileComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'spring-cloud-config',
    component: SpringCloudConfigComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'file',
    component: FileUploadComponent,
    canActivate: [AuthenticationGuard,AdminGuard],
  },
  {
    path: 'audit',
    component: AuditComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'whiteList',
    component: WhiteListComponent,
    canActivate: [AuthenticationGuard,AdminGuard],
  },
  {
    path: '**',
    redirectTo: 'login',
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash:true})],
  exports: [RouterModule],
})

export class AppRoutingModule {}
