import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class CouchdbService {
  constructor(private http: HttpClient) {}

  getAllComapnies() {
    const url = environment.API_URL + '/document/dev/all';
    return this.http.get(url);
  }


  getAllPreProductionComapnies() {
    const url = environment.API_URL + '/document/prod/all';
    return this.http.get(url);
  }

  CloneCompany(exisitingCompanyID: string, newCompanyID: string) {
    const url =
      environment.API_URL + '/document/dev/clone/' + exisitingCompanyID + '/' + newCompanyID;
    return this.http.get(url, {responseType: 'text'});
  }
  CloneCompanyInProduction(exisitingCompanyID: string, newCompanyID: string) {
    const url =
      environment.API_URL + '/document/dev/clone/' + exisitingCompanyID + '/' + newCompanyID;
    return this.http.get(url, {responseType: 'text'});
  }
  getAllCssComapnies() {
    const url = environment.API_URL + '/document/dev/allCss';
    return this.http.get(url)
  }
  getAllCssProductionComapnies() {
    const url = environment.API_URL + '/document/prod/allCss';
    return this.http.get(url)
  }
}
