import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  private url = environment.API_URL + '/document/dev';
  private documentId = "fileStorage";

  constructor(private http: HttpClient) { }

  getDocument() {
    const url = `${this.url}/${this.documentId}`;
    return this.http.get<any>(url);
  }
  updateDocument(
    documentId:string,
    updatedDocument: any,
    headers?: HttpHeaders
  ): Observable<boolean> {
    const Surl = `${this.url}/${documentId}`;
    return this.http.post<boolean>(Surl, updatedDocument, { headers });
  }

}
