import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {TokenStorageService} from './token-storage.service';
import {environment} from "../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isLoggedInSubject.asObservable(); //This 2 lines are for glboal boolean

  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService
  ) {}

  login(credentials: { username: string; password: string }): Observable<any> {
    return this.http.post(environment.API_URL + '/authenticate', credentials, httpOptions);
  }


  register(user: {
    first_name:string;
    last_name:string;
    username: string;
    email: string;
    role: string;
    password: string;
    addedBy: string;
  }): Observable<any> {
    return this.http.post(environment.API_URL + '/signup', user, httpOptions);
  }

  setLoggedIn(value: boolean) {
    this.isLoggedInSubject.next(value);
  }

  isLoggedIn(): boolean {
    const token = this.tokenStorageService.getToken();
    return !!token;
  }
}
