import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {NodeService} from "./node.service";

@Injectable(
  {providedIn: "root"}
)
export class StrategyService {
  constructor(private http: HttpClient,private nodeService: NodeService) { }

  getClientCalculationMethods(currentSelection: string) {
    return this.nodeService
      .getFileSystem(currentSelection+'_calculationMethods')
  }
}
