<div class="card">
  <p-treeTable [value]="treeData" [columns]="cols" [tableStyle]="{ 'min-width': '50rem' }">
    {{logger(treeData)}}
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 30%;">Company</th>
            <th style="width: 20%;">Class</th>
            <th style="width: 30%;">Value</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
      <tr>
          <td style="width: 20%;">{{ rowData.company }}</td>
          <td style="width: 20%">
              <input class="inputField" type="text" [(ngModel)]="rowData.class"
                 [style]="{ width: '100%' }">
          </td>
          <td style="width: 30%">
              <p-toggleButton [(ngModel)]="rowData.value" onLabel="true" offLabel="false" onIcon="pi pi-check"
                  offIcon="pi pi-times" [style]="{ width: '6rem' }"></p-toggleButton>
          </td>
      </tr>
   </ng-template>
</p-treeTable>
<div style="width: 100%; text-align: center; background-color: #e6e8ea">
  <p-button style="
        display: inline-block;
        margin: 0 auto;
        padding: 3px;
        background-color: #e6e8ea;
      " (click)="addCssClass()">save</p-button>
</div>
</div>
<!-- <p-table [value]="cssLoginConfigArray" *ngIf="displayCssLoginConfigTable" [style]="{'width': '100%'}">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 20%;">Property</th>
        <th style="width: 20%;">Value</th> 
        <th style="width: 60%;">Description</th> 
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-cssProperty>
      <tr>
        <td style="width: 20%;">{{ cssProperty.key }}</td>
        <td pEditableColumn style="width: 20%"> 
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="cssProperty.value">
            </ng-template>
            <ng-template pTemplate="output">
              {{ cssProperty.value }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn style="width: 60%"> 
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text">
            </ng-template>
            <ng-template pTemplate="output">
              <span>
              </span>
            </ng-template>
          </p-cellEditor>
        </td>
      </tr>
    </ng-template>
  </p-table> -->
<p-toast></p-toast>















