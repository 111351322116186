import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  providers: [MessageService],
})
export class NotificationComponent implements OnInit {
  constructor(private messageService: MessageService) {}

  ngOnInit(): void {}
  popUpServces() {
    this.messageService.add({
      severity: 'error',
      summary: 'GeeksforGeeks',
      detail: 'Error Service Message',
    });
  }
}
