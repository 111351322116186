<div style="display: flex; flex-direction: row; justify-content: space-between;">
  <h4>
    Label:
    <label style="color: #3399cc; flex: 0;">{{ unformatName(addNode_Label) }}</label>
  </h4>

  <h4>
    Full key:
    <label style="color: #3399cc; flex: 1;">{{ unformatName(parent_label) + "." + unformatName(addNode_Label) }}</label>
  </h4>

  <button class="close-btn" (click)="closeWindow()">&times;</button>
</div>

<div class="card">
  <p-treeTable [value]="treeData" [columns]="cols" [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          {{ col.header }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
      <tr>
        <td *ngFor="let col of columns; let i = index">
          <div class="firstColumn">
            <p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0"></p-treeTableToggler>
            <ng-container *ngIf="col.field === 'company'">
              <td>
                {{ rowNode.node.children ? formatName(rowData[col.field]) : rowData[col.field] }}
              </td>
            </ng-container>
            <ng-container *ngIf="i === 0"> </ng-container>
          </div>
          <ng-container *ngIf="i > 0">
            <ng-container *ngIf="i === 1 || i === 2">
              <td class="custom-cell2">
                <input
                  class="inputFiled"
                  type="text"
                  [ngModel]="rowData[col.field.split('.')[0]]?.[col.field.split('.')[1]]"
                  (ngModelChange)="onValueChange($event, rowData, col.field.split('.'))"
                />
              </td>
            </ng-container>
            <ng-container *ngIf="i === 3">
              <td>
                <div class="VisibilitToggle">
                  <p-toggleButton
                    [(ngModel)]="rowData.Visibility"
                    onLabel="true"
                    offLabel="false"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    [style]="{ width: '6rem' }"
                  ></p-toggleButton>
                </div>
              </td>
            </ng-container>
            <ng-container *ngIf="i === 4">
              <td>
                <div class="requirdToggle">
                  <p-toggleButton
                    [(ngModel)]="rowData.required"
                    onLabel="true"
                    offLabel="false"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    [style]="{ width: '6rem' }"
                  ></p-toggleButton>
                </div>
              </td>
            </ng-container>

    <ng-container *ngIf="i === 5 ">
      <td class="custom-cell2">
        <input class="inputFiled" type="text" [(ngModel)]="rowData.description">
      </td>
    </ng-container>
            <ng-container *ngIf="i === 6 ">
              <td class="custom-cell2">
                <input class="inputFiled" type="text" [(ngModel)]="rowData.required">
              </td>
            </ng-container>
</ng-container>
</td>
</tr>
</ng-template>
</p-treeTable>
<div style="width: 100%; text-align: center; background-color: #e6e8ea">
  <p-button class="save-btn" style="
        display: inline-block;
        margin: 0 auto;
        padding: 3px;
        background-color: #e6e8ea;
      " (click)="addNode()">save</p-button>
</div>
</div>
<p-toast style="border: none; display: none;"></p-toast>
