<div class="container">
<h5>Whitelisted IPs</h5>
  <p-table [value]="whiteListed" dataKey="name"[loading]="loading" styleClass="custom-data-table">
    <ng-template pTemplate="header">
      <tr>
        <th>key</th>
        <th>Name</th>
        <th>Address</th>
        <th>Operations</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-whitelistEntry let-i="rowIndex">
      <tr>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="whiteListed[i].value.name">
            </ng-template>
            <ng-template pTemplate="output">
              {{whiteListed[i].value.name}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="whiteListed[i].value.nameAddress">
            </ng-template>
            <ng-template pTemplate="output">
              {{whiteListed[i].value.nameAddress}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="whiteListed[i].value.address" required>
            </ng-template>
            <ng-template pTemplate="output">
              {{whiteListed[i].value.address}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td style="width: 30%; text-align: center;">
          <button class="delete-btn" (click)="ondeleteColumn( whiteListed[i].name)">
            <i class="pi pi-fw pi-trash"></i>
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="button-container">
    <p-button label="Save" (click)="saveWhiteListedIps(whiteListed)"></p-button>
    <p-button icon="pi pi-plus" (click)="op1.toggle($event)"></p-button>
  </div>
</div>


<p-overlayPanel #op1 [dismissable]="true" [showCloseIcon]="true" class="custom"
                [style]="{ 'background-color': '#162837' }">
  <ng-template pTemplate="content">
    <div class="AddNode">
      <label>key</label><input class="custom-input" type="text" pInputText [(ngModel)]="nameIp"
                                required="" />
      <label>Name</label><input class="custom-input" type="text" pInputText [(ngModel)]="addressNameIp"
                                 required="" />
      <label>Address</label><input class="custom-input" type="text" pInputText [(ngModel)]="addressIp"
                                   required="" />

      <p></p>
      <div class="submit-btn-container">
        <p-button class="submit-btn"  label="Submit" style="
        display: flex;
        place-self: center;
      " icon="pi pi-check" (click)="
        op1.hide()
      "(click)=" createAndAddAddress(whiteListed,nameIp,addressNameIp,addressIp)"></p-button>
      </div>

    </div>
  </ng-template>
</p-overlayPanel>
<p-confirmDialog></p-confirmDialog>
