

 import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { AuthService } from '../services/auth.service';
 import {UserServiceService} from "../services/user-service.service";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private tokenService: TokenStorageService,
    private authService: AuthService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let tokenStatus = this.authService.isLoggedIn()
      console.log("tokenStatus", tokenStatus);

    if (!tokenStatus) {
      this.router.navigate(['/login']);
      return false;
    } else if (tokenStatus) return true;

    return false;
  }
}
