import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { NotificationsService } from '../services/notifications.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  username: string ="";

  constructor(
    private authService: AuthService,
    private tokenStorageService: TokenStorageService,
    private router: Router,
    private messageService: MessageService,
    private notificationService: NotificationsService
  ) {}

  ngOnInit(): void {
      this.authService.isLoggedIn$.subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
      if(this.isLoggedIn)
      {
        this.username = this.tokenStorageService.getUser().username
      }
    });
    this.isLoggedIn = this.authService.isLoggedIn();
    this.username = this.tokenStorageService.getUserFullName()
  }
 register(){
  this.router.navigate(['/register'])
 }

  onSubmit(): void {
    this.authService.login(this.form).subscribe(
      (response) => {
        this.tokenStorageService.clearStorage()
        this.tokenStorageService.saveToken(response.token);
        this.tokenStorageService.saveUser(response);
        this.tokenStorageService.setLoggedInUsername(response.username)

        this.authService.setLoggedIn(true);//This is a global boolean

        //THese are for HTML component
        this.isLoginFailed = false;
        this.isLoggedIn = true;

        this.router.navigate(["/tree"])

      },
      (err) => {
        this.errorMessage = err.error.message;
        console.log(err);

        this.notificationService.errorMessage("Error", err.error.error)
        this.isLoginFailed = true;
      }
    );
  }
}
