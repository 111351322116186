<div class="container" *ngIf="currentUser; else loggedOut">
    <header class="jumbotron">
      <h3>
        <strong>{{ currentUser.username }}</strong> Profile
      </h3>
    </header>
    <p>
      <strong>Token:</strong>
      {{ currentUser.token.substring(0, 20) }} ...
      {{ currentUser.token.substr(currentUser.token.length - 20) }}
    </p>
    <p>
      <strong>Email:</strong>
      {{ currentUser.email }}
    </p>
    <strong>Roles:</strong>
    <ul>
      <li *ngFor="let role of currentUser.roles">
        {{ role.name }}
      </li>
    </ul>
  </div>
  
  <ng-template #loggedOut>
    Please login.
  </ng-template>
  