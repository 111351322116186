import { Component, OnInit } from '@angular/core';
import { EditorModule } from 'primeng/editor';
import { FileStorageService } from '../services/file-storage.service';
import { NotificationsService } from '../services/notifications.service';
import { HttpResponse } from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-spring-cloud-config',
  templateUrl: './spring-cloud-config.component.html',
  styleUrls: ['./spring-cloud-config.component.css'],
})
export class SpringCloudConfigComponent implements OnInit {
  text: any;
  profileList: any[] = [];
  selectedProfile: any;
  newProfile: string = '';
  showNewProfileField: boolean = false;
  isReadOnly: boolean = true;

  constructor(
    private filestorageService: FileStorageService,
    private notificationsService: NotificationsService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.loadListProfiles();
  }

  addProfile() {
    if (!this.showNewProfileField) {
      this.showNewProfileField = true;
    } else if (!this.isNewProfileFiledEmpty()) {
      this.createProfile();
      this.uploadDocument();
      this.clearInput();
      this.loadListProfiles();
    } else {
      this.notificationsService.errorMessage('Error', 'Profile name is empty');
    }
  }

  clearInput() {
    this.showNewProfileField = false;
    this.newProfile = '';
  }

  isNewProfileFiledEmpty() {
    return this.newProfile == '';
  }

  isProfileFieldEmpty() {
    return this.selectedProfile == null;
  }

  createProfile() {
    const newProfile = { label: this.newProfile };
    this.selectedProfile = newProfile;
    this.profileList = [...this.profileList, newProfile];
    console.log(this.profileList);

    this.text = '';
  }

  loadListProfiles() {
    this.filestorageService.getProfiles().subscribe((data) => {
      this.profileList = data.map((row: any) => ({
        label: row.name,
      }));
    });
  }

  loadProfile(profileName: any) {
    if (profileName) {
      this.filestorageService.getProfile(profileName).subscribe((data) => {
        this.text = data;
      });
    }
  }

  convertHtmlToPlainText() {
    this.text.replace(/\n\s*\n/g, '\n\n');
    const transformedText = this.text
      .replace(/<\/p>/g, '\n')
      .replace(/<p>/g, '')
      .replace(/<br>/g, '\n');
    return transformedText;
  }

  displayNotification(event: any) {
    if (event instanceof HttpResponse) {
      console.log('Request succeeded:', event);
      if (event.status === 200) {
        this.notificationsService.sucessMessage(
          'Success',
          this.selectedProfile.label + ' has been updated successfully'
        );
      } else {
        console.error('Request failed:', event);
        this.notificationsService.errorMessage('Error', 'Request failed');
      }
    }
  }

  resetWindow() {
    this.loadListProfiles();
    this.selectedProfile = null;
    this.text = null;
  }

  deleteProfile() {
    this.filestorageService.deleteProfile(this.selectedProfile.label).subscribe(
      (event) => {
        this.notificationsService.sucessMessage(
          'Success',
          'Succesfully deleted'
        );
        this.resetWindow();
      },
      (error) => {
        console.error('Request failed:', error);
        this.notificationsService.errorMessage('Error', 'Request failed');
      }
    );
  }

  uploadDocument(): void {
    const plainText = this.convertHtmlToPlainText();
    const blob = new Blob([plainText], { type: 'text/plain' });
    const file = new File([blob], this.selectedProfile.label, {
      type: 'text/plain',
    });

    this.filestorageService.uploadText(file).subscribe(
      (event) => {
        this.displayNotification(event);
      },
      (error) => {
        console.error('Request failed:', error);
        this.notificationsService.errorMessage('Error', 'Request failed');
      }
    );
  }

  switchReadOnlyValue() {
    this.isReadOnly = !this.isReadOnly;
  }

  confirmDelete() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteProfile();
      },
    });
  }
}
