import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { QuestConfigService } from 'src/app/services/quest-config.service';
import { FormsModule } from '@angular/forms';
import { OrderListModule } from 'primeng/orderlist';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { NodeService } from 'src/app/services/node.service';
import { ToastModule } from 'primeng/toast';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { RegisterComponent } from './register/register.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthenticationGuard } from './guards/authentication.guard';
import { TreeModule } from 'primeng/tree';
import { RouterModule } from '@angular/router';
import { RippleModule } from 'primeng/ripple';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { AuthHttpInterceptorService } from './helpers/auth.interceptor';
import { TreeComponent } from './tree/tree.component';
import { CommonModule } from '@angular/common';
import { TreeSelectModule } from 'primeng/treeselect';
import { ClipboardModule } from 'ngx-clipboard';
import { MenuModule } from 'primeng/menu';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MenubarModule } from 'primeng/menubar';
import { CheckboxModule } from 'primeng/checkbox';
import { NavbarComponent } from './navbar/navbar.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { AddToTreeComponent } from './add-to-tree/add-to-tree.component';
import { MessagesModule } from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { NotificationComponent } from './notification/notification.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CssComponent } from './css/css.component';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {ProgressBarModule} from 'primeng/progressbar';
import { AddToCssComponent } from './add-to-css/add-to-css.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { UpdateFileComponent } from './update-file/update-file.component';
import { DialogService } from 'primeng/dynamicdialog';
import{ImagesService} from 'src/app/services/images.service';
import { FileUploadModule } from 'primeng/fileupload';
import { AuditComponent } from './audit/audit.component';

import { EditorModule } from 'primeng/editor';
import { SpringCloudConfigComponent } from './spring-cloud-config/spring-cloud-config.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { WhiteListComponent } from './white-list/white-list.component';
import { AddWhiteListIpComponent } from './add-white-list-ip/add-white-list-ip.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import { TreePreProductionComponent } from './tree-pre-production/tree-pre-production.component';
import { DatabaseChangeValidatorComponent } from './database-change-validator/database-change-validator.component';
import { CssPreProductionComponent } from './css-pre-production/css-pre-production.component';
import { AddedUsersInterfaceComponent } from './added-users-interface/added-users-interface.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { StrategyComponent } from './components/strategy/strategy.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ProfileComponent,
    TreeComponent,
    NavbarComponent,
    AddToTreeComponent,
    NotificationComponent,
    CssComponent,
    AddToCssComponent,
    FileUploadComponent,
    UpdateFileComponent,
    AuditComponent,
    SpringCloudConfigComponent,
    WhiteListComponent,
    AddWhiteListIpComponent,
    TreePreProductionComponent,
    DatabaseChangeValidatorComponent,
    CssPreProductionComponent,
    AddedUsersInterfaceComponent,
    StrategyComponent,

  ],
  imports: [
    MenubarModule,
    TreeSelectModule,
    OverlayPanelModule,
    MenuModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    ButtonModule,
    DropdownModule,
    FormsModule,
    BrowserAnimationsModule,
    OrderListModule,
    TableModule,
    HttpClientModule,
    DialogModule,
    ListboxModule,
    TreeModule,
    TreeTableModule,
    ToastModule,
    MultiSelectModule,
    InputTextModule,
    ContextMenuModule,
    RouterModule,
    RippleModule,
    ToggleButtonModule,
    ClipboardModule,
    VirtualScrollerModule,
    CheckboxModule,
    DynamicDialogModule,
    MessagesModule,
    MessageModule,
    TableModule,
    CalendarModule,
    SliderModule,
    ProgressBarModule,
    FileUploadModule,
    TableModule,
    EditorModule,
    ConfirmDialogModule,
    MatDialogModule,
    MatButtonModule,
    ProgressSpinnerModule
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptorService,
      multi: true,
    },
    DialogService,

    NodeService,
    AuthenticationGuard,
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
