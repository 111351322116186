<p-table #dt [value]="users" [(selection)]="selectedUsers" dataKey="id" styleClass="p-datatable-customers" [rowHover]="true"
         [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
         [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [filterDelay]="0" [globalFilterFields]="['username','first_name','last_name','role','addedBy','creationDate']">
  <ng-template pTemplate="caption">
    <div class="table-header">
      List of Added Users
      <span class="p-input-icon-left" style="margin-left: auto;"class="search-class">
    <i class="pi pi-search"></i>
    <input
      pInputText
      type="text"
      (input)="dt.filterGlobal($any($event.target).value, 'contains')"
      placeholder="Global Search"
      styleClass="input-search"
      style="padding-left: 2rem;"
    />
  </span>
    </div>

  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Id</th>
      <th>Username</th>
      <th>First Name</th>
      <th>Last Name</th>
      <th>Role</th>
      <th>Added By</th>
      <th>Date</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-user>
    <tr class="p-selectable-row">
      <td>
        <span class="p-column-title">Id</span>
        {{user.id}}
      </td>
      <td>
        <span class="p-column-title">Username</span>
        {{user.username}}
      </td>
      <td>
        <span class="p-column-title">First Name</span>
        {{user.first_name}}
      </td>
      <td>
        <span class="p-column-title">Last Name</span>
        {{user.last_name}}
      </td>
      <td>
        <span class="p-column-title">Role</span>
        {{user.role}}
      </td>
      <td>
        <span class="p-column-title">Added By</span>
        {{user.addedBy}}
      </td>
      <td>
        <span class="p-column-title">Date</span>
        {{user.creationDate}}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8">No users found.</td>
    </tr>
  </ng-template>
</p-table>
