import { Injectable } from '@angular/core';
import {TreeNode} from "primeng/api";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NodeProductionService {
  private url = environment.API_URL + '/document/prod';
  public treeData: TreeNode[] = [];
  constructor(private http: HttpClient) {}

  getPreProductionFileSystem(client: string): Observable<any> {
    return this.http.get<any>(this.url + '/' + client).pipe(
      tap((data) => {
        console.log('Result of getFileSystem:', data);
        // You can process or use the data here as needed
      })
    );
  }
  getDocument(documentId: string) {
    const url = `${this.url}/${documentId}`;
    return this.http.get<any>(url);
  }

  processTreeData(data: any) {
    const { _id, _rev, ...rest } = data;

    return Object.values(rest).map((item: any) => {
      const { children, ...rest } = item;
      let data = { ...rest, Code: item.name };
      const treeNode: TreeNode = {
        label: item.name,
        data: data,
        children: this.processChildren(item.children, item.name),
        expanded: false,
      };

      return treeNode;
    });
  }

  processChildren(children: any[], parentLabel: string): TreeNode[] {
    if (!children) {
      return [];
    }
    return children.map((child: any) => {
      const { children, ...rest } = child;
      const rest2 = { ...rest, Code: parentLabel + '.' + child.name };
      const childTreeNode: TreeNode = {
        label: child.name,
        data: rest2,
        children: this.processChildren(
          child.children,
          parentLabel + '.' + child.name
        ),
      };
      return childTreeNode;
    });
  }

  processChildren2(child: TreeNode<any>[] | undefined): any[] {
    var formatedChldren: any[] = [];
    child?.forEach((node) => {
      const { label, data, children } = node;
      const { Wording, Visibility, name ,description} = data;
      const child = this.processChildren2(children);
      formatedChldren.push({
        Wording, Visibility, name ,description,
        children: child,
      });
    });
    return formatedChldren;
  }

  private getTreeNodeData(node: TreeNode<any> | undefined): any {
    if (!node) {
      return null;
    }
    return {
      name: node.data?.name,
      Visibility: node.data?.visibility,
      Wording: node.data?.wording,
      description: node.data?.description,
      children: this.getChildrenData(node.children),
    };
  }

  private getChildrenData(children: TreeNode<any>[] | undefined): any[] {
    if (!children || children.length === 0) {
      return [];
    }
    return children.map((child) => {
      this.getTreeNodeData(child);
    });
  }

  updateDocument(
    documentId: string,
    updatedDocument: any,
    headers?: HttpHeaders
  ): Observable<boolean> {
    const Surl = `${this.url}/${documentId}`;
    return this.http.post<boolean>(Surl, updatedDocument, { headers });
  }


}
