import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {NodeService} from "../services/node.service";
import {HttpHeaders} from "@angular/common/http";
import {NotificationsService} from "../services/notifications.service";

@Component({
  selector: 'app-add-white-list-ip',
  templateUrl: './add-white-list-ip.component.html',
  styleUrls: ['./add-white-list-ip.component.css']
})
export class AddWhiteListIpComponent implements OnInit {
  whiteListed: any;
  nameIp:string='';
  addressIp:string='';
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,private nodeService: NodeService,private notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.loadwhiteListedIps("Whitelist_IP");
    this.nameIp = this.config.data.name;
    this.addressIp = this.config.data.address;
  }
  loadwhiteListedIps(client:string): void {
    this.nodeService.getDocument(client).subscribe(
      (data: any) => {
        const { _id, _rev, ...rest } = data;
        if (Object.keys(rest).length > 0) {
          const documentArray = [];
          for (const key in rest) {
            if (rest.hasOwnProperty(key)) {
              documentArray.push({name: key, value: rest[key]});
            }
          }
          this.whiteListed = documentArray;
          console.log("loadwhiteListedIpsssss is",this.whiteListed)

        }
      },
      (error) => {
        console.error('Error fetching document:', error);
      }
    );
  }
  createAddress(
    name: string,
    address: string,

  ): any {
    return {
      name: name,
      address: address
    };
  }
  private createAndAddAddress(
    arrayOfAddress: any[],
    name: string,
    address: string,
  ): void {
    const newAddress = this.createAddress(name, address);
    arrayOfAddress.push(newAddress);
  }
  convertItem(address: any, index: number): any {
    const item: any = {
      name: index.toString(),
      value: {
        name: address.name,
        address: address.address,
      }
    };
    return item;
  }
  addNewAddress() {
    const ipAddress = this.createAddress(this.nameIp, this.addressIp);
    if (!Array.isArray(this.whiteListed)) {
      this.whiteListed = [];
    }
    console.log('not empty document', this.whiteListed)
    const item = this.convertItem(ipAddress, this.whiteListed.length);
    this.whiteListed.push(item);
    console.log('the addImage document is ', this.whiteListed);
    const toJson = this.transformArrayToObject(this.whiteListed);
    console.log('the toJson object is', toJson)
    this.saveWhiteListedIps(toJson);
  }
  transformArrayToObject(inputArray: { name: string, value: { name: string, address: string } }[]) {
    if (!inputArray || !Array.isArray(inputArray)) {
      console.error('Input array is undefined or not an array');
      return {};
    }

    const transformedObject: { [key: string]: any } = {};

    inputArray.forEach((item: { name: string, value: { name: string, address: string } }) => {
      transformedObject[item.name] = {
        "name": item.value.name,
        "address": item.value.address,
      };
    });

    return transformedObject;
  }
  saveWhiteListedIps(
    data: any = this.whiteListed
  ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })

    data._id= 'Whitelist_IP';
    console.log("the new document",data)
    var transformedObject= this.transformArrayToObject(data)
    console.log("the new transformedObjecttttt document",transformedObject)
    this.nodeService
      .updateDocument("Whitelist_IP", transformedObject, headers)
      .subscribe(
        (response:any) => {
          this.notificationsService.sucessMessage(
            'Success',
            'Item been modified for Whitelist_IP '
          );
          console.log("the updated document is")
          //const res=response
        },
        (errorResponse) => {
          console.error('Request failed:', errorResponse);
          if (errorResponse.status === 404) {
            // If conflict error (status code 409) received from the server
            const errorMessage = errorResponse.error.message; // Assuming the error message is in the "message" field of the error response
            this.notificationsService.errorMessage('Error', errorMessage);
          } else {
            // For other errors, display a generic error message
            this.notificationsService.errorMessage(
              'Error',
              'Request failed. Please try again later.'
            );
          }
        }
      );
  }
}
