import { Component, OnInit } from '@angular/core';
import { FileStorageService } from 'src/app/services/file-storage.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UpdateFileComponent } from 'src/app/update-file/update-file.component';
import{ImagesService} from 'src/app/services/images.service';
import { HttpHeaders } from '@angular/common/http';
import{NodeService} from 'src/app/services/node.service';
import {NotificationsService} from "../services/notifications.service";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationService} from "primeng/api";



@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  selectedFiles: FileList | null = null;
  currentFile: File | null = null;
  progress = 0;
  message = '';
  fileInfos: any[] = [];
  ref: DynamicDialogRef | undefined;
  name!:string;
  link!:string;
  selectedFile:boolean=false;
  document:any;
  _id!:string;
  _rev!:string;
  transformedDocument:any=[];
  sortOrder: number = 1;
  uploadedFiles: any[] = [];
  searchQuery: string = '';
  filteredDocument: any[] = [];
  loading:boolean=false;
  jsonObject: { [key: string]: any } = {};



  constructor(private fileStorageService: FileStorageService, public dialogService: DialogService,public imageService:ImagesService,public nodeService:NodeService,private notificationsService: NotificationsService,private confirmationService: ConfirmationService ) {}

  ngOnInit() {

    this.getImagesDocument();
    this.filterFiles()
    this.filteredDocument=this.document;


  this.fileStorageService.getFiles().subscribe((data: any) => {
    this.fileInfos = data;
    console.log('the file information are', this.fileInfos)
  });



  }

  selectFile(event: any) {
    this.selectedFiles = event.target.files;
    console.log(this.selectedFiles)
  }


  upload(){
    console.log('helloooo')
    this.progress = 0;
    let files: any = null;

    if (this.selectedFiles && this.selectedFiles.length > 0) {
      this.currentFile = this.selectedFiles.item(0);
      if (this.currentFile) {
        this.fileStorageService.upload(this.currentFile).subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * (event.loaded || 0)) / (event.total || 1));
            } else if (event instanceof HttpResponse) {
              this.message = event.body.message;
              this.fileStorageService.getFiles().subscribe((data: any) => {
                files = {
                  name: data.name,
                  link: data.link
                };
              });
            }
          },
          (err: any) => {
            this.progress = 0;
            this.message = 'Could not upload the file!';
            this.currentFile = null;
          }
        );
      }
    }

    this.selectedFiles = null;
  }

  showFileUpadateComponent() {
    console.log('hello showFileUpadateComponent');

    if (this.selectedFiles && this.selectedFiles.length > 0) {
      const selectedFile = this.selectedFiles[0];
      console.log('Selected File:', selectedFile);

      const config = {
        data: {
          nameConfig: selectedFile.name,
          selectedFiles: this.selectedFiles
        }
      };

      this.ref = this.dialogService.open(UpdateFileComponent, config);
    }
  }
  getImagesDocument(): any {
    this.loading = true;
    this.imageService.getDocument().subscribe(
      (data: any) => {
        const { _id, _rev, ...rest } = data;
        this._id = _id;
        this._rev = _rev;

        if (Object.keys(rest).length > 0) {
          const documentArray = [];
          for (const key in rest) {
            if (rest.hasOwnProperty(key)) {
              documentArray.push({ name: key, value: rest[key] });
            }
          }

          this.document = documentArray;
          this.filteredDocument = documentArray;
          console.log('the array is ', this.filteredDocument);
          this.transformedDocument = this.transformArrayToObject(this.filteredDocument);
          console.log('the transformed object is:', this.transformedDocument);
        }
        this.loading = false;

      },
      (error) => {
        console.error('Error fetching document:', error);
      }
    );

   // return this.document || [];
  }




  saveImage(
    data: any = this.filteredDocument
  ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })

    data._id= 'fileStorage';
    console.log("the new document",data)
    var transformedObject= this.transformArrayToObject(data)
    console.log("the new transformedObjecttttt document",transformedObject)
    this.loading = true;
    this.imageService
      .updateDocument("fileStorage", transformedObject, headers)
      .subscribe(
        (response:any) => {
          this.notificationsService.sucessMessage(
            'Success',
            'Item been modified for fileStorage '
          );
          console.log("the updated document is",this.jsonObject)
          this.loading = false;
          this.getImagesDocument();

        },
        (errorResponse) => {
          console.error('Request failed:', errorResponse);
          if (errorResponse.status === 404) {
            // If conflict error (status code 409) received from the server
            const errorMessage = errorResponse.error.message; // Assuming the error message is in the "message" field of the error response
            this.notificationsService.errorMessage('Error', errorMessage);
          } else {
            // For other errors, display a generic error message
            this.notificationsService.errorMessage(
              'Error',
              'Request failed. Please try again later.'
            );
            this.loading = false;

          }
        }
      );
  }

  transformArrayToObject(inputArray: { name: string, value: any }[]) {
    const transformedObject: { [key: string]: any } = {};

    inputArray.forEach((item: { name: string, value: any }, index: number) => {
      transformedObject[index.toString()] = {
        "id": item.value.id,
        "name": item.value.name,
        "url": item.value.url,
        "description": item.value.description
      };
    })

      transformedObject["_id"] = this._id;
    transformedObject["_rev"] = this._rev;

    return transformedObject;
  }
  onSort(event: any) {
    const sortOrder = event.order === 1 ? 1 : -1;
    this.transformedDocument = this.transformedDocument.sort((a: any, b: any) => {
      return a.value.name.localeCompare(b.value.name) * sortOrder;
    });
  }





  logger(data: any) {
    console.log(data);
  }

  ondeleteColumn(key: any, id: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this element?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true;
        this.nodeService.deleteFromDocument(this._id, key).subscribe(
          (response) => {
            this.fileStorageService.deleteFile(id).subscribe(
              () => {
                this.filteredDocument = [...this.filteredDocument];
              }
            );
            this.loading = false;
            this.notificationsService.sucessMessage('Success', 'Item has been modified for fileStorage');
            this.getImagesDocument();
          },
          (error) => {
            console.error('Delete operation failed:', error);
            this.notificationsService.errorMessage('Error', 'Delete operation failed. Please try again later.');
            this.loading = false;
          }
        );
      },
      reject: () => {
      }
    });
  }


  filterFiles() {
    const query = this.searchQuery.toLowerCase().trim();

    if (query) {
      this.filteredDocument = this.document.filter((element: any) => {
        const nameMatch = element.value.name.toLowerCase().includes(query);
        const linkMatch = (element.value.url ?? '').toLowerCase().includes(query);
        const descriptionMatch = (element.value.description ?? '').toLowerCase().includes(query);

        return nameMatch || linkMatch || descriptionMatch;
      });
    } else {
      this.filteredDocument = this.document;
    }
  }
}
