
<div class="container">
  <p-treeSelect
    [(ngModel)]="selectedCompany"
    [options]="companiesList"
    selectionMode="single"
    placeholder="Select Company"
    (onNodeSelect)="onNodeSelectClient($event)">
  </p-treeSelect>
</div>
<ng-container *ngIf="calculationMethodDocument">
  <ul class="calculationMethodList">
    <li *ngFor="let calculationMethod of calculationMethodDocument.calculationMethod | keyvalue">
      <div (click)="onMethodClick(calculationMethod)">
        <i *ngIf="!calculationMethod.value.expanded"
           class="pi pi-chevron-right">
         <span> {{ calculationMethod.value['name'] }}</span>
        </i>
        <i *ngIf="calculationMethod.value.expanded"
           class="pi pi-chevron-down">
          <span> {{ calculationMethod.value['name'] }}</span>
        </i>
      </div>
      <ul *ngIf="calculationMethod.value.expanded">
        <li *ngFor="let element of calculationMethod.value.children">
          <span [class.calculationMethodListSelected]="element.applicable"
            (click)="onClick(element,calculationMethod.value.children)">{{ element.name }}</span>
        </li>
      </ul>
    </li>
    <button pButton class="save-button" label="Save" (click)="save()">
    </button>
  </ul>
</ng-container>
