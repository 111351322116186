import { Component, OnInit } from '@angular/core';
import { TreeNode, MessageService } from 'primeng/api';
import { NodeService } from '../services/node.service';
import { HttpHeaders } from '@angular/common/http';
import { CouchdbService } from '../services/couchdb.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotificationsService } from '../services/notifications.service';


@Component({
  selector: 'app-add-to-css',
  templateUrl: './add-to-css.component.html',
  styleUrls: ['./add-to-css.component.css']
})

export class AddToCssComponent implements OnInit {
  treeData: TreeNode[] = [];
  cols: any[] = [];
  jsonObject: { [key: string]: any } = {};
  searchQuery: string = '';
  filteredTreeData: TreeNode[] = [];
  companiesList: any[] = [];
  selectedCompany: any;
  addNode_Label: string = '';
  addNode_French: string = '';
  addNode_English: string = '';
  addNode_Visibility: boolean = true;
  treeDataCompany: TreeNode[] = [];

  parent_label: string = '';
  node_label: string = '';

companiesCssList: any[] = [];
  africinvestCss: any;
  company: any;
  cssClasses: any;
  cssLoginConfig: any;
  clonedCssClasses: any; // Store the cloned cssClasses
  tableData: any[] = []; // Declare tableData as an array, adjust the type as needed
  index!: number;
  displayCssClassesTable: boolean = false; // Variable to control the visibility of the table
  displayCssLoginConfigTable: boolean = false;
  toggleState = false;
  cssDocument: any; // Assuming you store the CouchDB document here
  cssClassArray: any[] = [];
  cssLoginConfigArray: any[] = [];
  addCssClass_Class: string = '';
  addCssClass_Value: boolean = false;
  addCssConfigClass_Property:string='';
  addCssConfigClass_Value:string='';
  addCssConfigClass_Description:string='';



  constructor( private nodeService: NodeService,
    private couchdbService: CouchdbService,
    private messageService: MessageService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.loadCompanies();

     this.addCssClass_Class = this.config.data.class;
     this.addCssClass_Value = this.addCssClass_Value ??this.config.data.value;


  }


  loadCompanies() {
    this.couchdbService
  .getAllCssComapnies()
  .toPromise()
  .then((data: any) => {
    this.companiesList = data.rows.map((row: any) => ({
      label: row.id,
      data: row.id,
    }));
    this.companiesList.forEach((element) => {
      let cssClass:any = {
        label: this.parent_label,
        data: {
          company: element.label,
          class: this.addCssClass_Class,
          value: this. addCssClass_Value
        },
      };
      this.treeData.push(cssClass);
      this.treeData = [...this.treeData];

    });
  });
}



// addCssClass() {
//   console.log('we are adding nodes');
//   this.companiesList.forEach((company) => {
//     this.nodeService.getCssFileSystem(company.label)
//       .subscribe((data: any) => {
//         data.push(
//           this.createCssClass(
//             this.addCssClass_Class,
//             this.addCssClass_Value
//           )
//         );
//         console.log('hello')

//         console.log('this is the data ' + JSON.stringify(data));

//         //this.save(company.label ); // saves the node

//       },
//       (error) => {
//         console.error('An error occurred:', error);
//         // You can handle the error here, e.g., show an error message.
//       });
//   });
// }

addCssClass(): void {

  this.companiesList.forEach((company) => {
    this.nodeService.getCssFileSystem(company.label)
      .subscribe((data: any) => {
        if (data && data.cssClasses) {


          let className = this.addCssClass_Class;
          let value = this.addCssClass_Value;
          data.cssClasses[className] = value;
          console.log('this is new cssClasses', JSON.stringify(data, null, 2));
          this. saveCssClass(company.label, data)

        } else {
          console.error('Error: Data or data.cssClasses is undefined.');
        }
      },
      (error) => {
        console.error('Error fetching data:', error);
      });
  });
}















  logger(data: any) {
    console.log(data);
  }

  saveCssClass(company: string, data: any) {
    // Define the headers
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.nodeService.getDocument(company).subscribe(
      (existingDocument) => {
        if (existingDocument) {
          // You have the existing document, proceed with the update
          this.nodeService.updateDocument(company, data,headers).subscribe(
            (updatedDocument) => {
              console.log('Document Updated:', updatedDocument);
              // Success handling, e.g., show a success message.
              // You might not want to reload the page, but this depends on your use case.
            },
            (error) => {
              console.error('Error updating the document:', error);
            }
          );
        } else {
          console.error('No existing document found. Cannot update.');
        }
      },
      (error) => {
        console.error('Error retrieving the existing document:', error);
      }
    );
  }



  // transformDataToJson(id: string, rev: string, data:any) {
  //   const result: {
  //     _id: string;
  //     _rev: string;
  //     cssClasses: { [key: string]: boolean };
  //     cssLoginConfig: { [key: string]: string };
  //   } = {
  //     "_id": id, // Set the _id value as desired
  //     "_rev": rev, // Set the _rev value as desired
  //     "cssClasses": {},
  //     "cssLoginConfig": {}
  //   };

  //   for (const item of data) {
  //     if (typeof item.value === 'boolean') {
  //       result.cssClasses[item.key] = item.value;
  //     } else {
  //       result.cssLoginConfig[item.key] = item.value as string;
  //     }
  //   }

  //   return result;
  // }

  unformatName(name: string) {
    return name.trim().replace(/ /g, '_').toUpperCase();
  }

  createCssClass(
    cssClass: string,
    cssValue: boolean
  ):any  {
    return {
      data: {
        class:cssClass,
        value: cssValue
      },
    };
  }



}
