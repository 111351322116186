import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {Modification} from "../models/modification";
import {AuditService} from "../services/audit.service";
import {ConfirmationService, TreeNode} from "primeng/api";
import {StatusEnum} from "../models/status-enum";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-database-change-validator',
  templateUrl: './database-change-validator.component.html',
  styleUrls: ['./database-change-validator.component.css']
})
export class DatabaseChangeValidatorComponent implements OnInit {
  selectedModifications: any[] = [];
  modifications: Modification[] = [];
  ischanged: boolean = false;
  loading: boolean = false;
  first: number = 0;
  rows: number = 5;
  rowOptions: number[] = [5, 10, 20, 50];
  @Input() statusList: StatusEnum[] = [StatusEnum.ACCEPTED, StatusEnum.REJECTED, StatusEnum.ON_PROCESS];


  constructor(private auditService: AuditService, private confirmationService: ConfirmationService) {}

  ngOnInit(): void {
    this.loadAuditTable();
  }

  loadAuditTable() {
    this.loading = true;
    this.auditService.getAudits().subscribe(
      (modifications: Modification[]) => {
        this.modifications = modifications;
        this.loading = false;

        // Find the index of the first modification with status "ON_PROCESS"
        const index = this.modifications.findIndex(mod => mod.status === 'ON_PROCESS');

        // If an "ON_PROCESS" modification is found, set the `first` property to show the correct page
        if (index !== -1) {
          this.first = Math.floor(index / this.rows) * this.rows;
        }
      },
      (error) => {
        this.loading = false;
        console.error('Error fetching modifications:', error);
      }
    );
  }
  getStatusColor(status: string): string {
    switch (status) {
      case 'on_process':
        return 'yellow';
      case 'rejected':
        return 'red';
      case 'accepted':
        return 'green';
      default:
        return 'black';
    }
  }


  logger(data: any) {
    console.log(data);
    if (data.status) {
      this.ischanged=true;
      console.log('Input data changed:', this.ischanged);
    }
  }
  detectStatusChange(id: number, newStatus: StatusEnum) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to change the status?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true;
        this.ischanged = true;
        if (this.ischanged) {
          this.auditService.updateStatus(id, newStatus).subscribe(
            () => {
              console.log('Status updated successfully.');
              this.ischanged = false;
              this.loadAuditTable();
            },
            error => {
              this.loading = false;
              console.error('Error updating status:', error);
            }
          );
        }
      },
      reject: () => {
        console.log('Status change cancelled.');
      }
    });
  }
  onStatusChange(id: number, newStatus: StatusEnum) {
    this.detectStatusChange(id, newStatus);
  }



  isOnProcess(modification: Modification) {
    return modification?.status === StatusEnum.ON_PROCESS;
  }

  calculateFirstForLastPage() {
    const totalRecords = this.modifications.length;
    this.first = Math.floor((totalRecords - 1) / this.rows) * this.rows;
  }


  protected readonly filter = filter;
  protected readonly HTMLInputElement = HTMLInputElement;
  as: any;
  value: any;
}
