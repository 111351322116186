<div class="header" style="font-size: 50px">Add a New User</div>
<div class="container">
  <img id="profile-img" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" class="profile-img-card" />
  <form *ngIf="!isSuccessful" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div class="form-group">
      <input type="text" class="form-control" name="first_name" placeholder="First Name" [(ngModel)]="form.first_name"
      required minlength="3" maxlength="20" #first_name="ngModel" />
      <div class="alert-danger" *ngIf="f.submitted && first_name?.invalid">
        <div *ngIf="first_name?.errors?.required">First Name is required</div>
        <div *ngIf="first_name?.errors?.minlength">
          First Name must be at least 3 characters
        </div>
        <div *ngIf="first_name?.errors?.maxlength">
          First Name must be at most 20 characters
        </div>
      </div>
    </div>
    <div class="form-group">
      <input type="text" class="form-control" name="last_name" placeholder="Last Name" [(ngModel)]="form.last_name"
        required minlength="3" maxlength="20" #last_name="ngModel" />
      <div class="alert-danger" *ngIf="f.submitted && last_name?.invalid">
        <div *ngIf="last_name?.errors?.required">Last Name is required</div>
        <div *ngIf="last_name?.errors?.minlength">
          Last Name must be at least 3 characters
        </div>
        <div *ngIf="last_name?.errors?.maxlength">
          Last Name must be at most 20 characters
        </div>
      </div>
    </div>
    <div class="form-group">
      <input type="text" class="form-control" name="username" placeholder="User Name" [(ngModel)]="form.username"
      required minlength="3" maxlength="20" #username="ngModel" />
      <div class="alert-danger" *ngIf="f.submitted && username?.invalid">
        <div *ngIf="username?.errors?.required">User Name is required</div>
        <div *ngIf="username?.errors?.minlength">
          User Name must be at least 3 characters
        </div>
        <div *ngIf="username?.errors?.maxlength">
          User Name must be at most 20 characters
        </div>
      </div>
    </div>

    <div class="form-group">
      <input type="email" placeholder="Email" class="form-control" name="email" [(ngModel)]="form.email" required email
        #email="ngModel" />
      <div class="alert-danger" *ngIf="f.submitted && email?.invalid">
        <div *ngIf="email?.errors?.required">Email is required</div>
        <div *ngIf="email?.errors?.email">
          Email must be a valid email address
        </div>
      </div>
    </div>
    <div class="form-group">
      <input type="text" placeholder="Role" class="form-control" name="role" [(ngModel)]="form.role"
             required minlength="3" #role="ngModel" />
      <div class="alert-danger" *ngIf="f.submitted && role?.invalid">
        <div *ngIf="role?.errors?.required">role is required</div>
        <div *ngIf="role?.errors?.minlength">
          role must be at least 3 characters
        </div>
      </div>
    </div>

    <div class="form-group">
      <input type="password" placeholder="Password" class="form-control" name="password" [(ngModel)]="form.password"
        required minlength="6" #password="ngModel" />
      <div class="alert-danger" *ngIf="f.submitted && password?.invalid">
        <div *ngIf="password?.errors?.required">Password is required</div>
        <div *ngIf="password?.errors?.minlength">
          Password must be at least 6 characters
        </div>
      </div>
    </div>
    <div class="form-group" style="display: flex; justify-content: center">
      <button class="button">Add</button>
    </div>

    <div class="alert alert-warning" *ngIf="f.submitted && isSignUpFailed">
      Signup failed!<br />{{ errorMessage }}
    </div>
  </form>

  <div class="alert alert-success" *ngIf="isSuccessful">
    Your registration is successful!
  </div>
</div>
