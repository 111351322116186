import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';
import {LoginComponent} from "../login/login.component";

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  public TOKEN_KEY = 'auth-token';
  public USER_KEY = 'auth-user';

  constructor() {}

  private usernameSubject = new BehaviorSubject<string>('');
  username$ = this.usernameSubject.asObservable();

  clearStorage(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(this.TOKEN_KEY);
    window.sessionStorage.setItem(this.TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(this.TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(this.USER_KEY);
    window.sessionStorage.setItem(this.USER_KEY, JSON.stringify(user));
  }

  public getUser(): any | null {
    const user = window.sessionStorage.getItem(this.USER_KEY);
    console.log("the user isssss",user)
    return user ? JSON.parse(user) : null;
  }
  setLoggedInUsername(username: string) {
    this.usernameSubject.next(username); // Update the username value
  }

  public getUserFullName(): string {
    const user = window.sessionStorage.getItem(this.USER_KEY);
    return user
      ? JSON.parse(user).first_name + ' ' + JSON.parse(user).last_name
      : '';
  }
  public getUserName(): string | null {
    const user = window.sessionStorage.getItem(this.USER_KEY);
    return user ?JSON.parse(user).username:'';
  }
}
