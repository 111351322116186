<p-table #tableref [value]="modifications" [(selection)]="selectedModifications"
         [style]="{ 'width': '100%', 'padding': '40px' }"
         [paginator]="true"
         [rows]="rows"
         [first]="first"
         [loading]="loading"
         [globalFilterFields]="['audit.user.id', 'audit.user.username', 'audit.date', 'audit.entities.name', 'audit.operationName', 'modifiedKey', 'oldValue', 'newValue']"
styleClass="custom-data-table" xmlns="http://www.w3.org/1999/html">
  <ng-template pTemplate="caption">
     Changes Validation's List
    <span class="p-input-icon-left">
      <i class="pi pi-search"></i>
      <input pInputText type="text" (input)="tableref.filterGlobal($any($event.target).value, 'contains')" placeholder="Global Search" />
    </span>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3%;">UserId</th>
      <th style="width: 10%;">User</th>
      <th style="width: 13%;">Date</th>
      <th style="width: 15%;">Modified Document</th>
      <th style="width: 10%;">Operation</th>
      <th style="width: 10%;">Modified Key</th>
      <th style="width: 20%;">Old Value</th>
      <th style="width: 15%;">New Value</th>
      <th style="width: 70%;">Status</th>
    </tr>

  </ng-template>
  <ng-template pTemplate="body" let-modification let-i="index">
    <tr>
      <td>{{ modification?.audit?.user?.id }}</td>
      <td>{{ modification?.audit?.user?.username }}</td>
      <td>{{ modification?.audit?.date }}</td>
      <td>{{ modification?.audit?.entities.name }}</td>
      <td>{{ modification?.audit?.operationName }}</td>
      <td>{{ modification?.modifiedKey }}</td>
      <td>{{ modification?.oldValue }}</td>
      <td>{{ modification?.newValue }}</td>
      <td>
        <div *ngIf="!isOnProcess(modification)">
          {{ modification?.status }}
        </div>
        <div *ngIf="isOnProcess(modification)">
          <p-dropdown [options]="statusList"
                      [(ngModel)]="modification.status"
                      (ngModelChange)="onStatusChange(modification.id, modification.status)"
                      placeholder="Update Status"
                      appendTo="body"></p-dropdown>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
<div class="page-container">
  <div class="page">
    <span class="choose-page-text">Choose Number of pages:</span>
    <select [(ngModel)]="rows">
      <option *ngFor="let option of rowOptions" [value]="option">{{ option }}</option>
    </select>
  </div>
</div>

<p-confirmDialog></p-confirmDialog>
