<div class="container">
    <div style="justify-content: space-between; display: flex" class="header">
        <div>
            <div class="select-delete-profile">
                <p-treeSelect style="display: inline-block" [(ngModel)]="selectedProfile" [options]="profileList"
                    selectionMode="single" placeholder="Select profile" class="treeSelector"
                    (onNodeSelect)="loadProfile($event.node?.label)"></p-treeSelect>
            </div>
        </div>
        <div class="new-profile">
            <span *ngIf="showNewProfileField" class="p-input-icon-right">
                <i *ngIf="!isNewProfileFiledEmpty()" class="pi pi-times-circle" (click)="clearInput()"></i>
                <input type="text" pInputText placeholder="Enter new profile name" [(ngModel)]="newProfile" />
            </span>
            <p-button label="Add profile" (onClick)="addProfile()"></p-button>
        </div>
    </div>

    <p-editor *ngIf="text != null" [readonly]="isReadOnly" [(ngModel)]="text" style="white-space: pre-line">
        <ng-template pTemplate="header"> </ng-template>
    </p-editor>
    <div style="display: flex; justify-content: space-between">
        <div class="delete-edit-buttons">
            <p-toast style="display: none"></p-toast>
            <p-confirmDialog [style]="{ width: '50vw' }" [closable]=false></p-confirmDialog>
            <p-button *ngIf="!isProfileFieldEmpty()" (click)="confirmDelete()" icon="pi pi-trash"
                label="Delete"></p-button>

            <p-button *ngIf="!isProfileFieldEmpty()" (click)="switchReadOnlyValue()"
                [icon]="isReadOnly ? 'pi pi-file' : 'pi pi-file-edit'" label="{{ isReadOnly ? 'Read' : 'Edit' }}">
            </p-button>
        </div>
        <p-button *ngIf="text" (onClick)="uploadDocument()" label="save"></p-button>
    </div>
</div>