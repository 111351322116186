import { Injectable } from '@angular/core';
import { Component } from '@angular/core';
import { Message } from 'primeng//api';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private messageService: MessageService) {}
  sucessMessage(summary: string = '', detail: string = '') {
    this.messageService.add({
      severity: 'success',
      summary: summary,
      detail: detail,
    });
  }

  errorMessage(summary: string = '', detail: string = '') {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
    });
  }
}
