<div class="container" >
  <div class="button-container">
    <p-treeSelect [(ngModel)]="selectedCompany" style="display: inline-block" [options]="companiesCssList"
      selectionMode="single" placeholder="Select Company" class="treeSelector"
      (onNodeSelect)="onNodeSelectCssCompany($event)"></p-treeSelect>
    <button pButton pRipple type="button" label="Display cssClasses" class="p-button-secondary"
      (click)="displayCssClassesTableOnClick()"></button>
    <button pButton pRipple type="button" label="Display cssLoginConfig" class="p-button-secondary"
      (click)="displayCssLoginConfigTableOnClick()"></button>
  </div>
  <div class="table-class">
    <p-table [value]="cssClassArray" *ngIf="displayCssClassesTable" [style]="{'width': '100%'}" [loading]="loading" styleClass="custom-data-table">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 20%;">Class</th>
          <th style="width: 30%;">Value</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cssClass>
        <tr>
          <td style="width: 20%;">{{ cssClass.key }}</td>
          <td style="width: 30%;">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="cssClass.value">
              </ng-template>
              <ng-template pTemplate="output">
                <p-toggleButton [(ngModel)]="cssClass.value" onLabel="true" offLabel="false" onIcon="pi pi-check"
                  offIcon="pi pi-times" [style]="{'width': '6rem'}"></p-toggleButton>
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
    </p-table>


    <p-table [value]="cssLoginConfigArray" *ngIf="displayCssLoginConfigTable" [style]="{'width': '100%'}" [loading]="loading">
      {{logger(cssLoginConfigArray)}}
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 20%;">Property</th>
          <th style="width: 20%;">Value</th>
          <th style="width: 60%;">Description</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cssProperty>
        <tr>
          <td style="width: 20%;">{{ cssProperty.value.id }}</td>
          <td pEditableColumn style="width: 20%">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="cssProperty.value.value">
              </ng-template>
              <ng-template pTemplate="output">
                {{ cssProperty.value.value }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn style="width: 60%">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="cssProperty.value.description">
              </ng-template>
              <ng-template pTemplate="output">
                {{ cssProperty.value.description}}
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
    </p-table>


    <div style="display: flex; padding-top: 10px; font-size: xx-large">


      <div>
        <p-button class="save-btn" label="Save" (click)="save()" *ngIf="selectedCompany"></p-button>
      </div>
    </div>
