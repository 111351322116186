<div class="container">
  <div style="display: flex; justify-content: space-between">
    <p-treeSelect style="display: inline-block; background-color: white" [(ngModel)]="selectedCompany"
                  [options]="companiesList" selectionMode="single" placeholder="Select Company" class="treeSelector"
                  (onNodeSelect)="onNodeSelectCompany($event)"></p-treeSelect>
    <p-overlayPanel #op [style]="{
        'background-color': '#ffffff',
        color: '#3399cc',
        padding: '10px',
        flex: '1',
        border: 'none',
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center'
      }">
      <div style="display: flex;flex-direction: row;align-items: center;">
        <p-treeSelect style="display: inline-block; background-color: white" [(ngModel)]="existingClientID"
                      [options]="companiesList" selectionMode="single" placeholder="Select Company" class="treeSelector"
                      required=""></p-treeSelect>
        <div class="AddNode" style="text-align: center;">
          <label></label><input class="custom-input2" style="margin-bottom: 10px;" placeholder="New Client" type="text"
                                pInputText [(ngModel)]="newClientID" required="" />

        </div>


      </div>
      <div style="text-align: right;">
        <p-button class="submit-btn" [disabled]="isNewClientFieldEmpty()" label="Submit" style=" display: inline-block;
      margin: 0 auto;" icon="pi pi-check" (click)="op.hide(); CloneCompanyInProduction()">
        </p-button>
      </div>
    </p-overlayPanel>

    <p-button class="customAdd" (click)="op.toggle($event)" icon="pi pi-plus" label="Add client"></p-button>
  </div>

  <div>&nbsp;</div>
  <div class="tree-table-container" style="display: inline-block">
    <p-treeTable #tt [value]="searchQuery ? filteredTreeData : treeData" [columns]="cols"
                 styleClass="custom-tree-table" [sortField]="'Name'" [sortOrder]="1" *ngIf="selectedCompany"
                 [paginator]="true" [rows]="10" style="height: 100vh" [filterMode]="'lenient'"[loading]="loading">
      <ng-template pTemplate="caption">
        Quest Configuration Table
        <span><button pTooltip="Display description"  pButton  (click)="switch()" *ngIf="selectedCompany" class="display-discr-btn" icon="pi pi-eye "></button></span>
        <span class="p-input-icon-left" style="margin-left: auto" *ngIf="selectedCompany">
          <i class="pi pi-search"></i><input type="text" pInputText placeholder="Global Search" (input)="tt.filterGlobal(
              $any($event.target).value, 'contains')" />
        </span>
      </ng-template>

      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [ttSortableColumn]="col.field">
            {{ col.header }}
            <p-treeTableSortIcon [field]="col.field"> </p-treeTableSortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr [ngClass]="{
            'parent-node': rowNode.node.children && rowNode.node.children.length > 0,
            'child-node': rowNode.node.children && rowNode.node.children.length === 0
          }">
          <td *ngFor="let col of columns; let i = index">
            <div class="firstColumn">
              <p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0"></p-treeTableToggler>
              <ng-container *ngIf="col.field === 'name'">
                <span class="custom-cell2" pTooltip="{{ rowData.description }}">
                  {{ rowNode.node.children ? formatName(rowData[col.field]) : rowData[col.field] }}
                </span>
              </ng-container>
            </div>

            <ng-container *ngIf="i > 0">
              <!-- This is for the french and english's labels -->
              <ng-container *ngIf="(i === 1 || i === 2) && rowNode.node.children && rowNode.node.children.length === 0">
                <span class="custom-cell2">
                  {{ rowData[col.field.split('.')[0]]?.[col.field.split('.')[1]] }}
                </span>
              </ng-container>
              <ng-container *ngIf="i === 3">
                <div class="VisibilitToggle">
                  <p-toggleButton [(ngModel)]="rowData.Visibility" onLabel="True" offLabel="False" onIcon="pi pi-check"
                                  offIcon="pi pi-times" [style]="{ width: '6rem' }" ></p-toggleButton>
                </div>
              </ng-container>
              <ng-container *ngIf="i === 4">
                <div class="VisibilitToggle">
                  <p-toggleButton [(ngModel)]="rowData.required" onLabel="True" offLabel="False" onIcon="pi pi-check"
                                  offIcon="pi pi-times" [style]="{ width: '6rem' }" ></p-toggleButton>
                </div>
              </ng-container>


              <ng-container *ngIf="i === 5">
                <span class="custom-cell2" pTooltip="{{ rowData.Code }}">
                  {{ rowData.Code }}
                </span>
              </ng-container>
              <ng-container *ngIf="i === 6 && isDescriptionVisible">
                <span class="custom-cell2">
                  {{ rowData.description }}
                </span>
              </ng-container>
            </ng-container>

          </td>
        </tr>

      </ng-template>
    </p-treeTable>
  </div>
  <div style="display: flex; flex-direction: column; position: fixed; bottom: 10px; right: 1.875rem; z-index: 1000;">

  </div>
</div>
