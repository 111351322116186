<div  class="card" *ngIf="isLoggedIn">
    <p-menubar [model]="items">
        <ng-template pTemplate="start">
            <img src="assets/img/logo.png" height="40" class="img" />
            <div style="position: relative;white-space: nowrap;">
                <h2>Quest Config</h2>
            </div>
        </ng-template>

    </p-menubar>
</div>