
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../environments/environment";
export interface ResponseMessage {
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class FileStorageService {

  private baseUrl = environment.API_URL ;

  constructor(private http: HttpClient) { }

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const req = new HttpRequest('POST', `${this.baseUrl}/upload/media`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  uploadText(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', `${this.baseUrl}/upload/profile`, formData, {
      reportProgress: true,
      responseType: 'text'
    });

    return this.http.request(req);
  }

  getFiles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/files`)
  }

  getProfile(profile: string){
    return this.http.get(`${this.baseUrl}/profile/${profile}`,   { responseType: 'text' })
  }

  getProfiles(): Observable<any> {
    console.log(`${this.baseUrl}/profiles}`);

    return this.http.get(`${this.baseUrl}/profiles`)
  }

  getFileByName(fileName: string){
    return this.http.get(`${this.baseUrl}/profile/${fileName}`)
  }
  deleteAllFiles():Observable<any>{
    return  this.http.delete(`${this.baseUrl}/files`)
  }
  deleteFile(name: string): Observable<ResponseMessage> {
    console.log('the file that will be deleted is ', name);
    const url = `${this.baseUrl}/file/${name}`;
    console.log(url);

    return this.http.delete<ResponseMessage>(url);
  }

  deleteProfile(name: string): Observable<ResponseMessage> {
    console.log('the file that will be deleted is ', name);
    const url = `${this.baseUrl}/profile/${name}`;
    console.log(url);
    return this.http.delete<ResponseMessage>(url);
  }

}
