import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AuditService} from 'src/app/services/audit.service';
import {StatusEnum} from "../models/status-enum";
import {Modification} from "../models/modification";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationService } from 'primeng/api';
import {first} from "rxjs/operators";
import {Table} from "primeng/table";



@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css']
})
export class AuditComponent implements OnInit {
  @ViewChild('tt') tt: Table; // Add this line to get a reference to the p-table component
  modifications: Modification[] = [];
  first: number = 0;
  rows: number = 5;
  selectedModifications: any[] = [];
  rowOptions: number[] = [5, 10, 20, 50];
  loading: boolean = false;
  @Input() statusList: StatusEnum[] = [StatusEnum.ACCEPTED, StatusEnum.REJECTED, StatusEnum.ON_PROCESS];


  constructor(private auditService: AuditService, private confirmationService: ConfirmationService) {}

  ngOnInit(): void {
   this.loadAuditTable();
  }

  loadAuditTable(){
    this.loading = true;
    this.auditService.getAudits().subscribe(
      (modifications: Modification[]) => {
        this.modifications = modifications;
        this.mergeModifications(modifications);
        this.loading = false;
        console.log("modifications loaded", this.modifications);
      },
      (error) => {
        console.error('Error fetching modifications:', error);
      }
    );
  }
  mergeModifications(modifications: any[]): any[] {
    const mergedModifications: any[] = [];
    // Iterate over each modification
    modifications.forEach(modification => {
      // Check if there's already a merged modification with the same audit id
      const existingMergedModification = mergedModifications.find(mergedMod => mergedMod.audit.id === modification.audit.id);
      if (existingMergedModification) {
        // If a merged modification exists, update its properties
        existingMergedModification.modifiedKey += `, ${modification.modifiedKey}`;
        existingMergedModification.oldValue += `, ${modification.oldValue}`;
        existingMergedModification.newValue += `, ${modification.newValue}`;
      } else {
        const newMergedModification = {
        audit: modification.audit,
          modifiedKey: modification.modifiedKey,
          oldValue: modification.oldValue,
          newValue: modification.newValue,
          details: `${modification.modifiedKey}: ${modification.oldValue} -> ${modification.newValue}`
      };
      mergedModifications.push(newMergedModification);
      }
    });

    return mergedModifications;
  }

  protected readonly Modification = Modification;
}
