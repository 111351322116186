import {StatusEnum} from "./status-enum";

export class Modification {
  id!: number;

  audit: any;

  modifiedKey!: string;

  oldValue!: string;

  newValue!: string;

  status!: StatusEnum;

  constructor() {
  }
}
