import { Component, OnInit } from '@angular/core';
import {NodeProductionService} from "../services/node-production.service";
import {PrimeNGConfig} from "primeng/api";
import {CouchdbService} from "../services/couchdb.service";
import {NotificationsService} from "../services/notifications.service";
import {HttpHeaders} from "@angular/common/http";

@Component({
  selector: 'app-css-pre-production',
  templateUrl: './css-pre-production.component.html',
  styleUrls: ['./css-pre-production.component.css']
})
export class CssPreProductionComponent implements OnInit {

  companiesCssList: any[] = [];
  treeData: any;
  searchQuery: string = '';
  selectedCompany: any;
  jsonObject: { [key: string]: any } = {};
  company: any;
  cssClasses: any;
  cssLoginConfig: any;
  toggleState = false;
  cssDocument: any;
  cssClassArray: any[] = [];
  cssLoginConfigArray: any[] = [];
  displayCssLoginConfigTable: boolean = false;
  displayCssClassesTable: boolean = false;
  loading: boolean = false;

  constructor(private nodeProductionService: NodeProductionService,
              private primengConfig: PrimeNGConfig,
              private couchdbService: CouchdbService,
              private notificationsService: NotificationsService
  ) {
  }

  ngOnInit(): void {
    this.loadCssCompanies();
    this.primengConfig.ripple = true;

  }


  loadCssCompanies() {
    this.couchdbService.getAllCssProductionComapnies().subscribe((data: any) => {
      this.companiesCssList = data.rows.map((row: any) => ({
        label: row.id,
        data: row.id,
      }));
    });
  }

  onNodeSelectCssCompany(event: any) {
    const company = event.node.label;
    this.loadCssTreeData(company);
    this.searchQuery = '';
  }

  loadCssTreeData(client: string): void {
    this.loading = true;
    this.nodeProductionService.getPreProductionFileSystem(client).subscribe(
      (data: any) => {
        this.treeData = data;
        console.log('this is the current treeData', JSON.stringify(this.treeData, null, 2));

        this.cssClassArray = Object.keys(data.cssClasses).map(key => ({
            key,
            value: data.cssClasses[key]
          }
        ));

        this.cssLoginConfigArray = Object.keys(data.cssLoginConfig).map(key => ({
          key,
          value: data.cssLoginConfig[key]
        }));
        this.loading = false;

      },
      (error: any) => {
        console.error('Error fetching tree data', error);
      }
    );
  }


  displayCssClassesTableOnClick() {
    this.displayCssClassesTable = true;
    this.displayCssLoginConfigTable = false;
  }
  displayCssLoginConfigTableOnClick() {
    this.displayCssLoginConfigTable = true;
    this.displayCssClassesTable = false;
  }


  save(company: string = this.selectedCompany.label, data: any = this.treeData) {
    // Define the headers
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.nodeProductionService.getDocument(company).subscribe(
      (existingDocument) => {
        if (existingDocument && existingDocument._rev) {
          data = [...this.cssClassArray, ...this.cssLoginConfigArray];

          const jsonObject = this.transformDataToJson(company, existingDocument._rev, data);
          this.loading = true;

          this.nodeProductionService.updateDocument(company, jsonObject ,headers).subscribe(
            (updatedDocument) => {
              console.log('Document updated successfully:', updatedDocument);
              this.loading = false;
              this.loadCssTreeData(company);
              this.notificationsService.sucessMessage(
                'Success',
                'Item been modified for ' + company
              );
              console.log('Document updated successfully:', company);

            },
            (updateError) => {
              this.notificationsService.errorMessage(
                'Error',
                'Request failed. Please try again later.'
              );
              this.loading = false;

            }

          );


        } else {
          console.error('No existing document found. Cannot update.');
          this.loading = false;

        }
      },
      (error) => {
        this.loading = false;

        console.error('Error retrieving the existing document:', error);
      }
    );
  }

  isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }


  transformDataToJson(id: string, rev: string, data: any[]) {
    const result: {
      _id: string;
      _rev: string;
      cssClasses: { [key: string]: boolean };
      cssLoginConfig: { [key: string]: string };
    } = {
      "_id": id,
      "_rev": rev,
      "cssClasses": {},
      "cssLoginConfig": {}
    };

    for (const item of data) {
      if (typeof item.value === 'boolean') {
        result.cssClasses[item.key] = item.value;
      } else {
        result.cssLoginConfig[item.key] = item.value as string;
      }
    }

    return result;
  }



  logger(data: any) {
    console.log(data);
  }

}
