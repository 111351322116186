<div class="container">
  <div style="display: flex; justify-content: space-between">
    <p-treeSelect style="display: inline-block; background-color: white" [(ngModel)]="selectedCompany"
                  [options]="companiesList" selectionMode="single" placeholder="Select Company" class="treeSelector"
                  (onNodeSelect)="onNodeSelectCompany($event)"></p-treeSelect>
    <p-overlayPanel #op [style]="{
        'background-color': '#e6e8ea',
        color: '#3399cc',
        padding: '10px',
        flex: '1',
        border: 'none',
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center'
      }">
      <div style="display: flex;flex-direction: row;align-items: center;">
        <p-treeSelect style="display: inline-block; background-color: white" [(ngModel)]="existingClientID"
                      [options]="companiesList" selectionMode="single" placeholder="Select Company" class="treeSelector"
                      required=""></p-treeSelect>
        <div class="AddNode" style="text-align: center;">
          <label></label><input class="custom-input2" style="margin-bottom: 0px;" placeholder="New Client" type="text"
                                pInputText [(ngModel)]="newClientID" required=""/>

        </div>


      </div>
      <div style="text-align: center;">
        <p-button [disabled]="isNewClientFieldEmpty()" label="Submit" style=" display: inline-block;
      margin: 0 auto;" icon="pi pi-check" (click)="op.hide(); CloneCompany()">
        </p-button>
      </div>
    </p-overlayPanel>
    <div *ngIf="isAdmin">
      <p-button class="customAdd" (click)="op.toggle($event)" icon="pi pi-plus" label="Add client"></p-button>
    </div>
  </div>

  <div>&nbsp;</div>
  <div class="tree-table-container" style="display: inline-block">
    <p-treeTable #tt [value]=" searchQuery ? filteredTreeData : treeData" [columns]="cols"
                 styleClass="custom-tree-table" [sortField]="'Name'" [sortOrder]="1" *ngIf="selectedCompany"
                 [paginator]="true" [rows]="10" style="height: 100vh" [filterMode]="'lenient'" [loading]="loading">
      <ng-template pTemplate="caption">
        Quest Configuration Table
        <span><button pTooltip="Display description" pButton (click)="switch()" *ngIf="selectedCompany"
                      class="display-discr-btn" icon="pi pi-eye "></button></span>
        <span class="p-input-icon-left" style="margin-left: auto" *ngIf="selectedCompany">
          <i class="pi pi-search"></i><input type="text" pInputText placeholder="Global Search" (input)="tt.filterGlobal(
              $any($event.target).value, 'contains')"/>
        </span>
      </ng-template>

      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [ttSortableColumn]="col.field" [style.width.px]="getColumnWidth(col.field)">
            {{ col.header }}
            <p-treeTableSortIcon [field]="col.field"></p-treeTableSortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr [ngClass]="{
            'parent-node':
              rowNode.node.children && rowNode.node.children.length > 0,
            'child-node':
              rowNode.node.children && rowNode.node.children.length === 0
          }">
          <td *ngFor="let col of columns; let i = index">
            <div class="firstColumn">
              <p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0"></p-treeTableToggler>
              <ng-container *ngIf="col.field === 'name'">
                <td class="custom-cell2" pTooltip=" {{
                    rowData.description
                  }}">
                  {{
                    rowNode.node.children
                      ? formatName(rowData[col.field])
                      : rowData[col.field]
                  }}
                </td>
              </ng-container>
              <ng-container *ngIf="i === 0">
                <p-overlayPanel #op1 [dismissable]="true" [showCloseIcon]="true" class="custom"
                                [style]="{ 'background-color': '#162837' }">
                  <ng-template pTemplate="content">
                    <div class="AddNode">
                      <h4 style="text-align: center">{{ getKey(rowNode) }}</h4>
                      <label>Label</label><input class="custom-input" type="text" pInputText [(ngModel)]="addNode_Label"
                                                 required=""/>
                      <label>English</label><input class="custom-input" type="text" pInputText
                                                   [(ngModel)]="addNode_English"
                                                   required=""/>
                      <label>French</label><input class="custom-input" type="text" pInputText
                                                  [(ngModel)]="addNode_French"
                                                  required=""/>
                      <label>Description</label><input class="custom-input" type="text" pInputText
                                                       [(ngModel)]="addNode_Description"
                                                       required=""/>
                      <label>Visibility</label>
                      <p-toggleButton [(ngModel)]="addNode_Visibility"
                                      onLabel="True"
                                      offLabel="False"
                                      onIcon="pi pi-check"
                                      offIcon="pi pi-times">

                      </p-toggleButton>
                      <label>Required</label>
                      <p-toggleButton [(ngModel)]="addNode_Required" onLabel="False"
                                      offLabel="False" onIcon="pi pi-check" offIcon="pi pi-times">

                      </p-toggleButton>


                      <p></p>
                      <p-button [disabled]="isAnyFieldEmpty()" label="Submit" style="
                          display: flex;
                          place-self: center;
                          margin-top: 20px;
                        " icon="pi pi-check" (click)="
                          show(
                            getKey(rowNode),
                            addNode_Label,
                            addNode_English,
                            addNode_French,
                            addNode_Description,
                            addNode_Visibility,
                            addNode_Required
                          );
                          op1.hide()
                        "></p-button>
                    </div>
                  </ng-template>
                </p-overlayPanel>
                <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-secondary p-button-text"
                          (click)="op1.toggle($event)"></p-button>
              </ng-container>
            </div>

            <ng-container *ngIf="i > 0">
              <!-- This is for the french and english's labels -->
              <ng-container *ngIf="
                  (i === 1 || i === 2) &&
                  rowNode.node.children &&
                  rowNode.node.children.length === 0
                ">
                <td class="custom-cell2">
                  <input class="inputFiled" type="text"
                         [ngModel]="rowData[col.field.split('.')[0]]?.[col.field.split('.')[1]]"
                         (ngModelChange)="
                      onValueChange($event, rowData, col.field.split('.'))
                    "/>
                </td>
              </ng-container>
              <ng-container *ngIf="i === 3">
                <td *ngIf="isLeaf(rowNode)" class="VisibilitToggle">
                  <div >
                    <p-toggleButton [(ngModel)]="rowData.Visibility"
                                    onLabel="true"
                                    offLabel="false"
                                    onIcon="pi pi-check"
                                    offIcon="pi pi-times"
                                    [style]="{ width: '6rem' }">

                    </p-toggleButton>
                  </div>
                </td>
              </ng-container>
              <ng-container *ngIf="i === 4">
                <td *ngIf="isLeaf(rowNode)">
                  <div
                       class="requiredToggle">
                    <p-toggleButton [(ngModel)]="rowData.required"
                                    onLabel="true"
                                    offLabel="false"
                                    onIcon="pi pi-check"
                                    offIcon="pi pi-times"
                                    [style]="{ width: '6rem' }">
                    </p-toggleButton>
                  </div>
                </td>
              </ng-container>

              <ng-container *ngIf="i === 5">
                <td class="custom-cell2" pTooltip=" {{ rowData.Code }}">
                  {{ rowData.Code }}
                </td>
              </ng-container>
              <ng-container *ngIf="i === 6 && isDescriptionVisible">
                <td class="custom-cell2">
                  <input class="inputFiled" type="text" [(ngModel)]="rowData.description">
                </td>
              </ng-container>
            </ng-container>

          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td (click)="op2.toggle($event)" [attr.colspan]="cols.length">
            <button pButton icon="pi pi-plus" label="Class" *ngIf="selectedCompany" class="add-class-btn"></button>
          </td>
        </tr>
      </ng-template>
    </p-treeTable>
    <div>
      <p-overlayPanel #op2 [dismissable]="true" [showCloseIcon]="true" [style]="{ 'background-color': '#162837' }"
                      class="custom">
        <ng-template pTemplate="content">
          <div class="AddNode">
            <label>Label</label><input class="custom-input" type="text" pInputText [(ngModel)]="addNode_Label"
                                       required=""/>
            <label>English</label><input class="custom-input" type="text" pInputText [(ngModel)]="addNode_English"
                                         required=""/>
            <label>French</label><input class="custom-input" type="text" pInputText [(ngModel)]="addNode_French"
                                        required=""/>
            <label>Description</label><input class="custom-input" type="text" pInputText
                                             [(ngModel)]="addNode_Description"
                                             required=""/>
            <label>Visibility</label>
            <p-toggleButton [(ngModel)]="addNode_Visibility" onLabel="True"
                            offLabel="False" onIcon="pi pi-check" offIcon="pi pi-times"></p-toggleButton>
            <label>Required</label>
            <p-toggleButton [(ngModel)]="addNode_Required" onLabel="True"
                            offLabel="False" onIcon="pi pi-check" offIcon="pi pi-times"></p-toggleButton>
            <p></p>
            <p-button [disabled]="isAnyFieldEmpty()" label="Submit" icon="pi pi-check" (click)="
                show(
                  '',
                    addNode_Label,
                            addNode_English,
                            addNode_French,
                            addNode_Description,
                            addNode_Visibility,
                            addNode_Required
                )
              "></p-button>
          </div>
        </ng-template>
      </p-overlayPanel>
      <div style="display: flex; flex-direction: column; position: fixed;  z-index: 1000;">
        <p-button style="font-size:larger ; margin-top: 20px; width: 120px;" label="Save" (click)="save()"
                  *ngIf="selectedCompany"></p-button>

      </div>
    </div>
  </div>
</div>

