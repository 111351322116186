import {Component, OnInit} from '@angular/core';
import {FileStorageService} from "../../services/file-storage.service";
import {CouchdbService} from "../../services/couchdb.service";
import {StrategyService} from "../../services/strategyService.service";
import {NodeService} from "../../services/node.service";
import {HttpHeaders} from "@angular/common/http";

@Component({
  selector: 'app-strategy',
  templateUrl: './strategy.component.html',
  styleUrls: ['./strategy.component.css']
})
export class StrategyComponent implements OnInit {
  companiesList: [];
  selectedCompany: any;
  calculationMethodDocument: CalculationMethodsDocument
  currentClientSelection: string;

  constructor(private couchdbService: CouchdbService,
              private nodeService: NodeService,
              private strategyService: StrategyService) {
  }

  ngOnInit(): void {
    this.loadCompanies();
  }

  loadCompanies() {
    this.couchdbService.getAllComapnies().subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          this.companiesList = data.map((row: any) => {
            return {
              label: row.id,
              data: row.id,
            };
          });
        }
      });
  }

  onNodeSelectClient(client: any) {
    this.currentClientSelection = client.node.data
    this.strategyService
      .getClientCalculationMethods(this.currentClientSelection)
      .subscribe(res => {
        let {_id, _rev, ...rest} = res;
        let calculationMethod = this.constructCalculationMethodsMap(rest);
        this.calculationMethodDocument = {_id, _rev, calculationMethod: calculationMethod}
      });
  }

  private constructCalculationMethodsMap(rest: Omit<any, "_rev" | "_id">) {
    let restMap = new Map<string, CalculationMethod>();
    for (const key in rest) {
      const objectView = this.transformToViewObject(rest, key);
      restMap.set(key, objectView);
    }
    return restMap;
  }

  private transformToViewObject(rest: Omit<any, "_rev" | "_id">, key: string) {
    return {
      ...rest[key],
      expanded: false,
      children: rest[key]
        .children.map((elem: any) => {
          return {...elem} as children;
        })
    } as CalculationMethod;
  }

  onMethodClick(calculationMethod: { value: CalculationMethod }) {
    calculationMethod.value.expanded = !calculationMethod.value.expanded;
  }

  onClick(calculationMethod: children, rest:children []) {
    rest.map(elem => elem.applicable = false)
    calculationMethod.applicable = true;
  }

  save() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let {object, _id, _rev} = this.extractNormalDocument()
    this.nodeService
      .updateDocument(this.currentClientSelection+'_calculationMethods',
        {_id,_rev,...object},
        headers)
      .subscribe()
  }

  private extractNormalDocument() {
    let object: { [key: string]: any } = {};
    const {_id, _rev} = this.calculationMethodDocument;
    this.calculationMethodDocument.calculationMethod.forEach((values, keys) => {
      object[keys] = {
        name: values.name,
        applicable: values.applicable,
        children: values.children
      }
    });
    return {object, _id, _rev};
  }
}

interface CalculationMethod {
  name: string;
  applicable: boolean;
  expanded: boolean;
  children: children[];
}
interface  children{
  name: string;
  applicable: boolean;
}
interface CalculationMethodsDocument {
  _id: string;
  _rev: string;
  calculationMethod: Map<string, CalculationMethod>;
}
