import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Observable } from 'rxjs';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})


//This function need to be updated to remove

export class AuthHttpInterceptorService implements HttpInterceptor {
  constructor(private tokenStorageService: TokenStorageService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      this.tokenStorageService.getToken() &&
      req.url !=
        'https://questconfigdb.aderivatives.com/quest-config/_all_docs?include_docs=true'
    ) {
      const token = this.tokenStorageService.getToken();
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(req);
  }
}
