<div class="window-container">
  <h4>
    File Name:
    <label style="color: #3399cc; flex: 0;">{{ name }}</label>
  </h4>
</div>

<div class="card">
  <span class="p-float-label">
    <input id="float-input" type="text" pInputText [(ngModel)]="fileName">
    <label for="float-input">Name</label>
  </span>
  <div class="input-spacing">
    <label for="description">Add Description</label>
    <textarea id="description" rows="8" cols="40" pInputTextarea autoResize="autoResize" style="font-size: 14px;" [(ngModel)]="description"></textarea>
  </div>
  <p></p>
  <div class="submit-btn-container">
    <p-button class="submit-btn" label="Submit" icon="pi pi-check" (click)="addImage()"></p-button>
  </div>
  
</div>
<p-toast></p-toast>
