import {Component, OnInit, ViewChild} from '@angular/core';
import {Table} from "primeng/table";
import {PrimeNGConfig} from "primeng/api";
import {UserServiceService} from "../services/user-service.service";
import {User} from "../models/user";

@Component({
  selector: 'app-added-users-interface',
  templateUrl: './added-users-interface.component.html',
  styleUrls: ['./added-users-interface.component.css']
})
export class AddedUsersInterfaceComponent implements OnInit {

  users: User[];

  selectedUsers: User[];


  loading: boolean = true;

  @ViewChild('dt') table: Table;

  constructor(private userService: UserServiceService, private primengConfig: PrimeNGConfig) {
  }

  ngOnInit() {
    this.userService.getAllUsers().subscribe(
      (users: any[]) => {
        this.users = users;
        console.log('userssssssss', this.users);
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching users', error);
        this.loading = false;
      }
    );
  }

}





