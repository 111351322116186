import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../services/token-storage.service';
import {UserServiceService} from "../services/user-service.service";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  items: MenuItem[] = [];
  isLoggedIn = false;
  username!: string;
  usernames!:any | null;
  isAdmin=false;
  constructor(
    private tokenStorageService: TokenStorageService,
    private authService: AuthService,
    private router: Router,
    private userService:UserServiceService
  ) {}

  ngOnInit(): void {
    this.authService.isLoggedIn$.subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
      if (this.isLoggedIn) {
        this.username = this.tokenStorageService.getUserFullName();
        this.usernames = this.tokenStorageService.getUserName();
        this.userService.getUserByUsername(this.usernames).subscribe(
          (response:any) => {
            this.isAdmin = response.authorities[0].authority=='ROLE_ADMIN';
            this.userService.setIsAdmin(this.isAdmin); // Set isAdmin in the shared service

            console.log("the value of isAdmin:", this.isAdmin);
            this.handleNavbarItems(); // Call a function to handle navbar items after isAdmin is updated

          },
          (error) => {
            console.error('Error fetching user details:', error);
          }
        );
        this.handleNavbarItems(); // Call a function to handle navbar items after isAdmin is updated
        this.getNavbarItems();

      }
    });
    this.isLoggedIn = this.authService.isLoggedIn();
    this.username = this.tokenStorageService.getUserFullName();
    this.usernames = this.tokenStorageService.getUserName();

    this.userService.getUserByUsername(this.usernames).subscribe(
      (response:any) => {
        this.isAdmin = response.authorities[0].authority=='ROLE_ADMIN';
        console.log("the value of isAdmin:", this.isAdmin);
        this.handleNavbarItems(); // Call a function to handle navbar items after isAdmin is updated

      },
      (error) => {
        console.error('Error fetching user details:', error);
      }
    );
    this.getNavbarItems();
    this.handleNavbarItems(); // Call a function to handle navbar items after isAdmin is updated
    console.log("test test isAdmin valueeeee",this.isAdmin);
  }
  handleNavbarItems() {
    // Modify navbar items based on this.isAdmin value
    this.getNavbarItems();
    console.log("test test isAdmin valueeeee", this.isAdmin);
  }


  getNavbarItems() {
    this.items = [
      {
        label: 'Spring cloud config',
        icon: 'pi pi-fw pi-cloud',
        command: () => {
          this.router.navigate(['/spring-cloud-config']);
        },
      },
      {
        label: 'Translation & Visibility',
        icon: 'pi pi-fw pi-table', // Icon class for the main menu item
        items: [ // Array of sub-menu items
          ...(this.isAdmin ? [{
            label: 'Production Profile', // Text for the first sub-menu item
            command: () => {
              this.router.navigate(['/treePreProduction']); // Navigates to '/treePreProduction' when clicked
            }
          }] : []),
          {
            label: 'Dev Profile', // Text for the second sub-menu item
            command: () => {
              this.router.navigate(['/tree']); // Navigates to '/tree' when clicked
            }
          },
          ...(this.isAdmin ? [{
            label: 'Changes Validation', // Text for the third sub-menu item
            icon: 'pi pi-check', // Icon class for the third sub-menu item
            command: () => {
              this.router.navigate(['/validate-changes']); // Navigates to '/validate-changes' when clicked
            }
          }] : [])
        ]
      },
      {
        label: 'Strategy',
        icon: 'pi pi-fw pi-compass',
        command: () => {
          this.router.navigate(['/strategy']);
        },
      },
      {
        label: 'Css',
        icon: 'pi pi-fw pi-palette',
        items: [
          {
            label: 'Dev Profil',
            command: () => {
              this.router.navigate(['/css']);
            }
          },
          ...(this.isAdmin ? [{
            label: 'Production Profil',
            command: () => {
              this.router.navigate(['/cssPreProduction']);
            }
          }] : [])
        ]
      },

      {
        label: 'File',
        icon: 'pi pi-fw pi-file',

        command: () => {
          this.router.navigate(['/file']);
          console.log('file');
        },
      },

      {
        label: "Audit",
        icon: 'pi pi-fw pi-history',
        command: () => {
          this.router.navigate(['/audit']);
          console.log("file");
        },
      },
      ...(this.isAdmin
          ? [
            {
              label: "White-listed IP addresses",
              icon: 'pi pi-server',
              command: () => {
                this.router.navigate(['/whiteList']);
              },
            },
          ]
          : []
      ),
      ...(this.isAdmin
          ? [
            {
              label: 'Add a new user',
              icon: 'pi pi-fw pi-user-plus',
              items: [
                {
                  label: 'Register',
                  command: () => {
                    this.router.navigate(['/register']);
                  },
                },
                {
                  label: 'Added Users',
                  command: () => {
                    this.router.navigate(['/addedUser']);
                  },
                },
              ],
            },
          ]
          : []
      ),

      {
        label: this.username,
        icon: 'pi pi-fw pi-user',
        items: [
          {
            label: 'Logout',
            icon: 'pi pi-fw pi-sign-out',
            command: () => this.logout(),
          },
        ],
      },
    ];
  }

    logout(): void {
    this.authService.setLoggedIn(false);
    this.tokenStorageService.clearStorage();
    this.router.navigate(['/login']);
  }
}
