import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TreeNode } from 'primeng/api';
import { HttpHeaders } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import {ResponseMessage} from "./file-storage.service";
import {environment} from "../../environments/environment";
interface ProcessedTreeNode {
  label: string;
  data: any;
  children: ProcessedTreeNode[];
}
@Injectable()
export class NodeService {
  private url = environment.API_URL + '/document/dev';

  public treeData: TreeNode[] = [];
  constructor(private http: HttpClient) {}

  getDocument(documentId: string) {
    const url = `${this.url}/${documentId}`;
    return this.http.get<any>(url);
  }

  getFileSystem(client: string): Observable<any> {
    return this.http.get<any>(this.url + '/' + client).pipe(
      tap((data) => {
        console.log('Result of getFileSystem:', data);
        // You can process or use the data here as needed
      })
    );
  }


  getCssFileSystem(client: string): Observable<any> {
    return this.http.get<any>(environment.API_URL + '/' + client);
  }

  saveAllNodes(label: string, newNode: TreeNode): Observable<any> {
    const url = this.url+`/updateAllDocument`;
    console.log("the url",url);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const payload = { label, newNode };
    console.log("payload",payload);
    return this.http.post<any>(url, payload, { headers });
  }
  processTreeData(data: any) {
    const { _id, _rev, ...rest } = data;

    return Object.values(rest).map((item: any) => {
      const { children, ...rest } = item;
      let data = { ...rest, Code: item.name };
      const treeNode: TreeNode = {
        label: item.name,
        data: data,
        children: this.processChildren(item.children, item.name),
        expanded: false,
      };

      return treeNode;
    });
  }

  processChildren(children: any[], parentLabel: string): TreeNode[] {
    if (!children) {
      return [];
    }
    return children.map((child: any) => {
      const { children, ...rest } = child;
      const rest2 = { ...rest, Code: parentLabel + '.' + child.name };
      const childTreeNode: TreeNode = {
        label: child.name,
        data: rest2,
        children: this.processChildren(
          child.children,
          parentLabel + '.' + child.name
        ),
      };
      return childTreeNode;
    });
  }

  processChildren2(child: TreeNode<any>[] | undefined): any[] {
    var formatedChldren: any[] = [];
    child?.forEach((node) => {
      const { label, data, children } = node;
      const { Wording, Visibility, name ,description,required} = data;
      const child = this.processChildren2(children);
      formatedChldren.push({
        Wording, Visibility, name ,description,required,
        children: child,
      });
    });
    return formatedChldren;
  }

  private getTreeNodeData(node: TreeNode<any> | undefined): any {
    if (!node) {
      return null;
    }
    return {
      name: node.data?.name,
      Visibility: node.data?.visibility,
      Wording: node.data?.wording,
      description: node.data?.description,
      required:node.data?.required,
      children: this.getChildrenData(node.children),
    };
  }

  private getChildrenData(children: TreeNode<any>[] | undefined): any[] {
    if (!children || children.length === 0) {
      return [];
    }
    return children.map((child) => {
      this.getTreeNodeData(child);
    });
  }

  printJSON(jsonObject: any): void {
    const jsonString = JSON.stringify(jsonObject, null, 2);
    console.log(jsonString);
  }

  updateDocument(
    documentId: string,
    updatedDocument: any,
    headers?: HttpHeaders
  ): Observable<any> {  // Changed from Observable<boolean> to Observable<any>
    const Surl = `${this.url}/${documentId}`;
    return this.http.post<any>(Surl, updatedDocument, { headers });
  }
  deleteFromDocument(documentId: string, key: string): Observable<any> {
    const headers = new HttpHeaders();
    const apiUrl = `${this.url}/${documentId}/${key}`;
    console.log('the URL is', apiUrl);
    console.log('the document id is ', documentId);
    console.log('the key is ', key);

    return this.http.delete(apiUrl);
  }

}
