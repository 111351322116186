<div class="container">
  <div class="button-container">
    <p-treeSelect [(ngModel)]="selectedCompany" style="display: inline-block" [options]="companiesCssList"
                  selectionMode="single" placeholder="Select Company" class="treeSelector"
                  (onNodeSelect)="onNodeSelectCssCompany($event)"></p-treeSelect>
    <button pButton pRipple type="button" label="Display cssClasses" class="p-button-secondary"
            (click)="displayCssClassesTableOnClick()"></button>
    <button pButton pRipple type="button" label="Display cssLoginConfig" class="p-button-secondary"
            (click)="displayCssLoginConfigTableOnClick()"></button>
  </div>
  <div class="table-class">
    <p-table [value]="cssClassArray" *ngIf="displayCssClassesTable" [style]="{'width': '100%'}" [loading]="loading" styleClass="custom-data-table">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 20%;">Class</th>
          <th style="width: 30%;">Value</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cssClass>
        <tr>
          <td style="width: 20%;">{{ cssClass.key }}</td>
          <td style="width: 30%;">
            <ng-container *ngIf="isBoolean(cssClass.value); else textValue">
              <p-toggleButton [(ngModel)]="cssClass.value" onLabel="true" offLabel="false" onIcon="pi pi-check"
                              offIcon="pi pi-times" [style]="{'width': '6rem'}"></p-toggleButton>
            </ng-container>
            <ng-template #textValue>
              {{ cssClass.value }}
            </ng-template>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <p-table [value]="cssLoginConfigArray" *ngIf="displayCssLoginConfigTable" [style]="{'width': '100%'}" [loading]="loading">
      {{logger(cssLoginConfigArray)}}
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 20%;">Property</th>
          <th style="width: 20%;">Value</th>
          <th style="width: 60%;">Description</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cssProperty>
        <tr>
          <td style="width: 20%;">{{ cssProperty.value.id }}</td>
          <td style="width: 20%">
            {{ cssProperty.value.value }}
          </td>
          <td style="width: 60%">
            {{ cssProperty.value.description }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
