<div class="container">
  <div *ngIf="currentFile" class="progress">
    <div
      class="progress-bar progress-bar-info progress-bar-striped"
      role="progressbar"
      attr.aria-valuenow="{{ progress }}"
      aria-valuemin="0"
      aria-valuemax="100"
      [ngStyle]="{ width: progress + '%' }"
    >
      {{ progress }}%
    </div>
  </div>
  <div class="file-upload-buttons">
    <label class="btn btn-default">
      <input type="file" (change)="selectFile($event)" />
    </label>

    <p-button label="Upload" [disabled]="!selectedFiles" (click)="showFileUpadateComponent()" class="upload-button"></p-button>
  </div>
  <div class="alert alert-light" role="alert">{{ message }}</div>
  <div class="center-table">
    <div class="search-class">
      <span class="p-input-icon-left" style="margin-left: auto">
        <i class="pi pi-search"></i>
        <input type="text" pInputText [(ngModel)]="searchQuery" (ngModelChange)="filterFiles()" placeholder="Search" />
      </span>
    </div>
    <p-table [value]="filteredDocument" [style]="{ 'width': '100%' }" [sortField]="name" [sortOrder]="1" [paginator]="true" [rows]="3" [loading]="loading" styleClass="custom-data-table">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 20%;" pSortableColumn="name">Name<p-sortIcon field="name"></p-sortIcon></th>
          <th style="width: 30%;">Link</th>
          <th style="width: 50%;">Description</th>
          <th style="width: 30%;">Delete column</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-document>
        <tr>
          <td style="width: 20%;" pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="document.value.name" style="width: 100%; height: 100px;">
              </ng-template>
              <ng-template pTemplate="output">
                {{ document.value.name }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td style="width: 30%;">
            <a [href]="document.value.url" target="_blank">{{ document.value.url }}</a>
          </td>
          <td style="width: 30%" pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="document.value.description" style="width: 100%; height: 100px;">
              </ng-template>
              <ng-template pTemplate="output">
                {{ document.value.description }}
              </ng-template>
            </p-cellEditor>
          <td style="width: 30%; text-align: center;">
            <button class="delete-btn" (click)="ondeleteColumn(document.name, document.value.id)">
              <i class="pi pi-fw pi-trash"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p></p>
  <div>
    <p-button label="Save" (click)="saveImage(document)"></p-button>
  </div>
</div>
<p-confirmDialog></p-confirmDialog>
