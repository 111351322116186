<div class="header" style="font-size: 50px">Welcome to Quest-Config</div>
<div class="container">
  <div class="left-content">
    <div class="login-form-content">
      <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      />
      <form
        *ngIf="!isLoggedIn"
        name="form"
        (ngSubmit)="f.form.valid && onSubmit()"
        #f="ngForm"
        novalidate
      >
        <div class="form-group">
          <input
            type="text"
            class="input_user_password"
            name="username"
            [(ngModel)]="form.username"
            required
            #username="ngModel"
            placeholder="Username"
          />
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="f.submitted && username.invalid"
          >
            Username is required!
          </div>
        </div>
        <div class="form-group">
          <input
            type="password"
            class="input_user_password"
            name="password"
            [(ngModel)]="form.password"
            required
            minlength="6"
            #password="ngModel"
            placeholder="Password"
          />
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="f.submitted && password.invalid"
          >
            <div *ngIf="password.errors?.required">Password is required</div>
            <div *ngIf="password.errors?.minlength">
              Password must be at least 6 characters
            </div>
          </div>
        </div>

        <div class="form-group">
          <button class="button-login">Login</button>
        </div>
        <div class="form-group">
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="f.submitted && isLoginFailed"
          >
            Login failed {{ errorMessage }}
          </div>
        </div>
      </form>

      <div
        class="alert alert-success"
        *ngIf="isLoggedIn"
        style="white-space: nowrap; text-align: center"
      >
        {{ username }}
      </div>
    </div>
    <div></div>
  </div>
</div>
