import { Component, OnInit } from '@angular/core';
import {NodeService} from "../services/node.service";
import {HttpHeaders} from "@angular/common/http";
import {NotificationsService} from "../services/notifications.service";
import {AddWhiteListIpComponent} from "../add-white-list-ip/add-white-list-ip.component";
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {Observable} from "rxjs";
import {ResponseMessage} from "../services/file-storage.service";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-white-list',
  templateUrl: './white-list.component.html',
  styleUrls: ['./white-list.component.css'],
  providers: [DialogService],

})
export class WhiteListComponent implements OnInit {
  whiteListed: any;
  document: any;
  name!: string;
  link!: string;
  _id!: string;
  _rev!: string;
  description!: string;
  ref: DynamicDialogRef | undefined;
  url!: string;
  filteredDocument:any
  nameIp:string='';
  addressNameIp:string='';
  addressIp:string='';
  loading = false;

  jsonObject: { [key: string]: any } = {};

  constructor(private nodeService: NodeService,  private notificationsService: NotificationsService,public dialogService: DialogService,private confirmationService: ConfirmationService

  ) {

  }

  ngOnInit(): void {
    this.loadwhiteListedIps("Whitelist_IP");
  }

  logger(data: any) {
    console.log(data);
  }
  loadwhiteListedIps(client:string): void {
    this.loading = true;
    this.nodeService.getDocument(client).subscribe(
      (data: any) => {
        const { _id, _rev, ...rest } = data;
        if (Object.keys(rest).length > 0) {
          const documentArray = [];
          for (const key in rest) {
            if (rest.hasOwnProperty(key)) {
              documentArray.push({name: key, value: rest[key]});
            }
          }
          this.whiteListed = documentArray;
          console.log("loadwhiteListedIpsssss is",this.whiteListed)

        }
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching document:', error);
        this.loading = false;

      }
    );
  }

  createAddress(
    name: string,
    nameAddress:string,
    address: string,

  ): any {
    return {
      name: name,
      nameAddress: nameAddress,
      address: address
    };

  }
   createAndAddAddress(
    arrayOfAddress: any[],
    name: string,
    nameAddress:string,
    address: string,
  ): void {
     const addressIndex = arrayOfAddress.length+1;

     const newAddress = this.createAddress(name,nameAddress, address);
     console.log("the newAddressSSSSS",newAddress);

     console.log("the newAddress issss",this.convertAddress(newAddress,addressIndex));
    arrayOfAddress.push(this.convertAddress(newAddress,addressIndex));
     console.log("arrayOfAddresssssssssss",arrayOfAddress);
     this.saveWhiteListedIps(arrayOfAddress);
  }
  convertAddress(address: any, index: number): any {
    const item: any = {
      name: index.toString(),
      value: {
        name: address.name,
        nameAddress: address.nameAddress,
        address: address.address,
      }
    };
    return item;
  }

  saveWhiteListedIps(
    data: any = this.whiteListed
  ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })

    data._id= 'Whitelist_IP';
    console.log("the new document",data)
    var transformedObject= this.transformArrayToObject(data)
    console.log("the new transformedObjecttttt document",transformedObject)
    this.loading = true;
    this.nodeService
      .updateDocument("Whitelist_IP", transformedObject, headers)
      .subscribe(
        (response:any) => {
          this.loading = false;
          this.notificationsService.sucessMessage(
            'Success',
            'Item been modified for Whitelist_IP '
          );
          console.log("the updated document is",this.jsonObject)
          //const res=response
        },
        (errorResponse) => {
          console.error('Request failed:', errorResponse);
          this.loading = false;

          if (errorResponse.status === 404) {
            // If conflict error (status code 409) received from the server
            const errorMessage = errorResponse.error.message; // Assuming the error message is in the "message" field of the error response
            this.notificationsService.errorMessage('Error', errorMessage);
          } else {
            // For other errors, display a generic error message
            this.notificationsService.errorMessage(
              'Error',
              'Request failed. Please try again later.'
            );
          }
        }
      );
  }

  transformArrayToObject(inputArray: { name: string, value: { name: string,nameAddress:string, address: string } }[]) {
    if (!inputArray || !Array.isArray(inputArray)) {
      console.error('Input array is undefined or not an array');
      return {};
    }

    const transformedObject: { [key: string]: any } = {};

    inputArray.forEach((item: { name: string, value: { name: string,nameAddress:string, address: string } }) => {
      transformedObject[item.name] = {
        "name": item.value.name,
        "nameAddress":item.value.nameAddress,
        "address": item.value.address,
      };
    });

    return transformedObject;
  }

  ondeleteColumn(key: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this element?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true;
        this.nodeService.deleteFromDocument("Whitelist_IP", key).subscribe(
          (response) => {
            this.notificationsService.sucessMessage(
              'Success',
              'Item has been modified for Whitelist_IP'
            );
            this.loadwhiteListedIps("Whitelist_IP");
            this.loading = false;
          },
          (error) => {
            this.notificationsService.errorMessage(
              'Error',
              'Request failed. Please try again later.'
            );
            this.loading = false;
          }
        );
      },
      reject: () => {
        this.loading = false; // Optionally handle rejection
      }
    });
  }


}


