import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FileStorageService } from 'src/app/services/file-storage.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ImagesService } from 'src/app/services/images.service';
import { HttpHeaders } from '@angular/common/http';
import {environment} from "../../environments/environment";



@Component({
  selector: 'app-update-file',
  templateUrl: './update-file.component.html',
  styleUrls: ['./update-file.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UpdateFileComponent implements OnInit {
  selectedFiles: FileList | null = null;
  currentFile: File | null = null;
  progress = 0;
  message = '';
  fileInfos: any[] = [];
  imageUrls: string[] = [];
  name!: string;
  link!: string;
  isSelected!: boolean;
  document: any;
  _id!: string;
  _rev!: string;
  transformedDocument: any = [];
  fileName!: string;
  description!: string;
  url!: string;


  @Input() selectedFile: File | null = null;

  constructor(
    public config: DynamicDialogConfig,
    private filestorageService: FileStorageService,
    public ref: DynamicDialogRef,
    private imageService: ImagesService,

  ) { }

  ngOnInit(): void {

    this.getImagesDocument();
    this.name = this.config.data.nameConfig;
    this.url = this.createLink();
    this.selectedFiles = this.config.data.selectedFiles;
  }


  upload() {
    this.progress = 0;
    let files: any = null;

    if (this.selectedFiles && this.selectedFiles.length > 0) {
      this.currentFile = this.selectedFiles.item(0);
      if (this.currentFile) {
        this.filestorageService.upload(this.currentFile).subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * (event.loaded || 0)) / (event.total || 1));
            } else if (event instanceof HttpResponse) {
              this.message = event.body.message;
              this.filestorageService.getFiles().subscribe((data: any) => {
                files = {
                  name: data.name,
                  link: data.link
                };
              });
            }
          },
          (err: any) => {
            this.progress = 0;
            this.message = 'Could not upload the file: ' + err.message;
            this.currentFile = null;
          }
        );
      }
    }

    this.selectedFiles = null;
    // window.location.reload();
  }
  createLink(): any {
    const prefix = environment.API_URL+"/file/";
    const suffix = this.name;
    return prefix.concat(suffix);
  }

  createImage(namefile: string, url: string, description: string): any {
    const id = this.name;
    const image = {
      id: id,
      name: namefile,
      url: url,
      description: description,
    };
    console.log('the image is', image);

    return image;
  }

  addImage() {
    const image = this.createImage(this.fileName, this.url, this.description);


    if (!Array.isArray(this.document)) {
      this.document = [];
    }

    console.log('not empty document', this.document)
    const item = this.convertItem(image, this.document.length);
    this.document.push(item);
    console.log('the addImage document is ', this.document);
    const toJson = this.transformArrayToObject(this.document);
    console.log('the toJson object is', toJson)
    this.saveImage(toJson);
    this.upload();
  }


  convertItem(image: any, index: number): any {
    const item: any = {
      name: index.toString(),
      value: {
        id: image.id,
        name: image.name,
        url: image.url,
        description: image.description
      }
    };
    return item;
  }


  getImagesDocument(): void {
    this.imageService.getDocument().subscribe(
      (data: any) => {
        const { _id, _rev, ...rest } = data;
        this._id = _id;
        this._rev = _rev;

        if (Object.keys(rest).length > 0) {
          const documentArray = [];
          for (const key in rest) {
            if (rest.hasOwnProperty(key)) {
              documentArray.push({ name: key, value: rest[key] });
            }
          }

          this.document = documentArray;
          console.log('the transformed object is:', this.transformedDocument);

          console.log('the document of getImagesDocument ', this.document);

        }
      },
      (error) => {
        console.error('Error fetching document:', error);
      }
    );
  }

  saveImage(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.imageService.getDocument().subscribe(
      (existingDocument) => {
        if (existingDocument) {
          this.imageService.updateDocument("fileStorage",data, headers).subscribe(

            (updatedDocument) => {
              console.log('Document Updated:', updatedDocument);
            },
            (error) => {
              console.error('Error updating the document:', error);
            }
          );
        } else {
          console.error('No existing document found. Cannot update.');
        }
      },
      (error) => {
        console.error('Error retrieving the existing document:', error);
      }
    );
  }

  // saveWhiteListedIps(
  //   data: any = this.whiteListed
  // ) {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //   })
  //
  //   data._id= 'Whitelist_IP';
  //   console.log("the new document",data)
  //   var transformedObject= this.transformArrayToObject(data)
  //   console.log("the new transformedObjecttttt document",transformedObject)
  //   this.nodeService
  //     .updateDocument("Whitelist_IP", transformedObject, headers)
  //     .subscribe(
  //       (response:any) => {
  //         this.notificationsService.sucessMessage(
  //           'Success',
  //           'Item been modified for Whitelist_IP '
  //         );
  //         console.log("the updated document is",this.jsonObject)
  //         //const res=response
  //       },
  //       (errorResponse) => {
  //         console.error('Request failed:', errorResponse);
  //         if (errorResponse.status === 404) {
  //           // If conflict error (status code 409) received from the server
  //           const errorMessage = errorResponse.error.message; // Assuming the error message is in the "message" field of the error response
  //           this.notificationsService.errorMessage('Error', errorMessage);
  //         } else {
  //           // For other errors, display a generic error message
  //           this.notificationsService.errorMessage(
  //             'Error',
  //             'Request failed. Please try again later.'
  //           );
  //         }
  //       }
  //     );
  // }




































  transformArrayToObject(inputArray: { name: string, value: any }[]) {
    if (!inputArray || !Array.isArray(inputArray)) {
      console.error('Input array is undefined or not an array');
      return {};
    }

    const transformedObject: { [key: string]: any } = {};

    inputArray.forEach((item: { name: string, value: any }, index: number) => {
      transformedObject[index.toString()] = {
        "id": item.value.id,
        "name": item.value.name,
        "url": item.value.url,
        "description": item.value.description
      };
    });

    transformedObject["_id"] = this._id;
    transformedObject["_rev"] = this._rev;

    return transformedObject;
  }

  /*transformArrayToObject(inputArray: { name: string, value: { name: string,nameAddress:string, address: string } }[]) {
    if (!inputArray || !Array.isArray(inputArray)) {
      console.error('Input array is undefined or not an array');
      return {};
    }

    const transformedObject: { [key: string]: any } = {};

    inputArray.forEach((item: { name: string, value: { name: string,nameAddress:string, address: string } }) => {
      transformedObject[item.name] = {
        "name": item.value.name,
        "nameAddress":item.value.nameAddress,
        "address": item.value.address,
      };
    });

    return transformedObject;
  }
*/





}
