import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import {UserServiceService} from "../services/user-service.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  usernames:any;
  isAdmin=false;
  constructor(
    private tokenService: TokenStorageService,
    private userService:UserServiceService,
  private router: Router
  ) {}

  canActivate(): Observable<boolean> {
   this.usernames = this.tokenService.getUserName();

    return this.userService.getUserByUsername(this.usernames).pipe(
      map((response: any) => {
        this.isAdmin = response.authorities[0].authority === 'ROLE_ADMIN';
        console.log("the value of isAdmin from AdminGuardddddddd:", this.isAdmin);
        if (!this.isAdmin) {
          this.router.navigate(['/']); // Redirect to home or another page if user doesn't have admin role
        }
        return this.isAdmin;
      })
    );
  }
}
