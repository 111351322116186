import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig, TreeNode} from 'primeng/api';
import {NodeService} from '../services/node.service';
import {HttpHeaders} from '@angular/common/http';
import {CouchdbService} from '../services/couchdb.service';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AddToTreeComponent} from '../add-to-tree/add-to-tree.component';
import {NotificationsService} from '../services/notifications.service';
import {TokenStorageService} from "../services/token-storage.service";
import {UserServiceService} from "../services/user-service.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css'],
  providers: [DialogService],
})
export class TreeComponent implements OnInit {
  treeData: TreeNode[] = [];
  cols: any[] = [];
  jsonObject: { [key: string]: any } = {};
  searchQuery: string = '';
  filteredTreeData: TreeNode[] = [];
  companiesList: any[] = [];
  selectedCompany: any;
  addNode_Label: string = '';
  addNode_French: string = '';
  addNode_English: string = '';
  addNode_Description: string = '';
  addNode_Visibility: boolean = true;
  addNode_Required: boolean = true;
  treeDataCompany: TreeNode[] = [];
  ref: DynamicDialogRef | undefined;
  existingClientID: any = null;
  newClientID: string = '';
  revision:string='';
  loading: boolean = false;
  revisionMap= new Map<string, string>();
  isDescriptionVisible: boolean = false;
  userName: any ;
  isAdmin: boolean;

  constructor(
    private nodeService: NodeService,
    private primengConfig: PrimeNGConfig,
    private couchdbService: CouchdbService,
    public dialogService: DialogService,
    private notificationsService: NotificationsService,
    private tokenService: TokenStorageService,
    public userService:UserServiceService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.loadCompanies();
    this.primengConfig.ripple = true;
    this.cols = [
      { field: 'name', header: 'Name', width: '300px', sortable: true },
      {
        field: 'Wording.french',
        header: 'French',
        width: '300px',
        sortable: true,
      },
      {
        field: 'Wording.english',
        header: 'English',
        width: '300px',
        sortable: true,
      },
      {
        field: 'Visibility',
        header: 'Visibility',
        width: '100px',
        sortable: true,
      },
      {
        field: 'required',
        header: 'Required',
        width: '100px',
        sortable: true,
      },
      {
        field: 'Code',
        header: 'Code',
        width: '800px',
        sortable: true,
      },

    ];
    this.userName = this.tokenService.getUserName();
    this.userService.isAdmin$.subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
      console.log("admiiiiiiinnnnnnnnnnn is  in treee",this.isAdmin);
    });
  }


  loadCompanies() {
    this.couchdbService.getAllComapnies().subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          this.companiesList = data.map((row: any) => {
            this.revisionMap.set(row.id, row.value.rev);
            return {
              label: row.id,
              data: row.id,
            };
          });
        } else {
          console.error(
            'Invalid data structure: Missing or empty data array.',
            data
          );
        }
        // Set loading to false after processing the data
      },
      (error) => {
        console.error('Error fetching companies:', error);
        // Set loading to false in case of an error
        this.loading = false;
      }
    );
  }


  onNodeSelectCompany(event: any):void {
    const company = event.node.label;
    this.loadTreeData(company);
    this.searchQuery = '';
  }

  onValueChange(newValue: any, rowData: any, fieldPath: string[]): void {
    if (fieldPath.length === 1) {
      rowData[fieldPath[0]] = newValue;
    } else {
      const firstField = fieldPath[0];
      const secondField = fieldPath[1];
      rowData[firstField] = rowData[firstField] || {};
      rowData[firstField][secondField] = newValue;
    }
  }

  save(
    company: string = this.selectedCompany.label,
    data: TreeNode<any>[] = this.treeData
  ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let index = 0;
    data.forEach((item) => {
      const { label, parent, children, data } = item;
      const { Wording, Visibility, name, description, required } = data;
      const child = this.nodeService.processChildren2(children);
      this.jsonObject[index] = {
        Wording,
        Visibility,
        name,
        description,
        required,
        children: child,
      };
      index++;
    });
    this.jsonObject['_id'] = company;
    this.jsonObject['_rev'] = this.revisionMap.get(company);

    this.nodeService.updateDocument(company, this.jsonObject, headers).subscribe(
      (response: any) => {
        if (response && response.message) {
          this.notificationsService.sucessMessage('Success', response.message);
        } else {
          this.notificationsService.sucessMessage(
            'Success',
            'Item has been modified for ' + company
          );
        }
        console.log('The updated document is', this.jsonObject);
        this.revisionMap.set(response._id, response._rev);
        console.log('After update', this.revisionMap);
      },
      (errorResponse) => {
        console.error('Request failed:', errorResponse);
        if (errorResponse.error && errorResponse.error.message) {
          this.notificationsService.errorMessage('Error', errorResponse.error.message);
        } else {
          this.notificationsService.errorMessage(
            'Error',
            'Request failed. Please try again later.'
          );
        }
      }
    );
  }


  loadTreeData(client: string) {
    this.loading = true;
    this.nodeService.getFileSystem(client).subscribe(
      (data: any) => {
        this.treeData = this.nodeService.processTreeData(data);
        console.log("test", this.treeData);
        // Set loading to false after processing the data
        this.loading = false;
      },
      (error: any) => {
        console.error('error fetching tree data', error);
        // Set loading to false in case of an error
        this.loading = false;
      }
    );
  }

  getKey(treeNode: any): string {
    if (!treeNode) {
      return '';
    }
    const label =
      treeNode.label || (treeNode.node && treeNode.node.label) || '';
    const key = this.getKey(treeNode.parent) + '.' + label;
    return key.startsWith('.') ? key.slice(1) : key;
  }

  logger(data: any) {
    console.log(data);
  }


  formatName(name: string): string {
    if (name != null) {
      return name
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/(?:^|\s)\S/g, function (a) {
          return a.toUpperCase();
        });
    } else {
      return '';
    }
  }

  show(
    parent: string,
    label: string,
    english: string,
    french: string,
    description:string,
    visibility: boolean,
    required:boolean
  ) {
    this.ref = this.dialogService.open(AddToTreeComponent, {
      header: 'Add node',
      showHeader: false,
      width: '70%',
      contentStyle: {
        overflow: 'auto',
        background: '#ffffff',
        color: '#6c757d',
        border: 'none',
      },
      baseZIndex: 10000,
      data: {
        parent: parent,
        label: label,
        english: english,
        french: french,
        description: description,
        visibility: visibility,
        required: required,
      },
    });
    this.ref.onClose.subscribe(() => {
      console.log('Dialog closed');
    });
  }

  isAnyFieldEmpty(): boolean {
    return (
      this.addNode_Label.trim() === '' ||
      this.addNode_English.trim() === '' ||
      this.addNode_French.trim() === ''  ||
      this.addNode_Description.trim() === ''


    );
  }

  isNewClientFieldEmpty(): boolean {
    return this.existingClientID === null || this.newClientID.trim() === '';
  }

  CloneCompany() {

        this.couchdbService
          .CloneCompany(this.existingClientID.label, this.newClientID)
          .subscribe((elemnt) => {});
        window.location.reload();


  }
  ondeleteColumn(key: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    console.log("the key to delete is ",key);

    this.nodeService.deleteFromDocument(this.selectedCompany.label, key).subscribe(
      (response) => {

        console.log('Delete operation was successful');
      },
      (error) => {
        console.error('Delete operation failed:', error);
      }
    );
  }
  getColumnWidth(field: string): number {
    switch (field) {
      case 'name':
        return 170; // Width in pixels
     // Width in pixels
      default:
        return 120; // Default width
    }
  }

  switch() {
  this.isDescriptionVisible = !this.isDescriptionVisible
    if(this.isDescriptionVisible) {
      this.cols = [
        { field: 'name', header: 'Name', width: '300px', sortable: true },
        {
          field: 'Wording.french',
          header: 'French',
          width: '300px',
          sortable: true,
        },
        {
          field: 'Wording.english',
          header: 'English',
          width: '300px',
          sortable: true,
        },
        {
          field: 'Visibility',
          header: 'Visibility',
          width: '100px',
          sortable: true,
        },
        {
          field: 'required',
          header: 'Required',
          width: '100px',
          sortable: true,
        },
        {
          field: 'Code',
          header: 'Code',
          width: '800px',
          sortable: true,
        },
        {
          field: 'description',
          header: 'description',
          width: '800px',
          sortable: true,
        }
      ];
    }
    else{
      this.cols = [
        { field: 'name', header: 'Name', width: '300px', sortable: true },
        {
          field: 'Wording.french',
          header: 'French',
          width: '300px',
          sortable: true,
        },
        {
          field: 'Wording.english',
          header: 'English',
          width: '300px',
          sortable: true,
        },
        {
          field: 'Visibility',
          header: 'Visibility',
          width: '100px',
          sortable: true,
        },
        {
          field: 'required',
          header: 'Required',
          width: '100px',
          sortable: true,
        },
        {
          field: 'Code',
          header: 'Code',
          width: '800px',
          sortable: true,
        }
      ];
    }
  }

  isLeaf(rowNode:any) {
    return rowNode.node.children && rowNode.node.children.length === 0
  }
}
